import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { Permission, validateWholeNumber } from "../../../../util";
import { uniq, debounce } from "lodash";
import { capitalizeFirstLetter, filterTableInfo } from "../../util";
import {
  Device,
  changeDeviceStatus,
  downloadCertificates,
  fetchAllDashboards,
  fetchDeviceWithSpecificAction,
  fetchTableInfo,
  fetchAllDeviceActions,
  getTenantFromURL,
  markActionAsCompleted,
  fetchAllStreamsWithDetails,
  FetchStreamsAPIResponse,
  completeAllActionsForDevices,
  DeviceActionType,
  devicesPerPageOptions,
  Filters,
  fetchAllUsers,
  fetchAllDevicesActionsFiltersOptions,
} from "../../../../BytebeamClient";
import DeviceOverview from "../../DeviceManagement/Devices/DeviceOverview";
import { LastActionType } from "../../DeviceManagement/Devices/LastActionInfoTable";
import {
  Divider,
  Grid,
  Icon,
  MenuItem,
  PaginationProps,
  Popup,
  Table,
} from "semantic-ui-react";
import LoadingAnimation from "../../../common/Loader";
import { StyledGridColumn } from "../../DeviceManagement/Devices/Device";
import { ActivateDeviceConfirmationModal } from "../../DeviceManagement/Devices/ActionModals/ActivateDeviceConfirmationModal";
import { ShowDeviceStreamsModal } from "../../DeviceManagement/Devices/ActionModals/ShowDeviceStreamsModal";
import { ShowConfigVersionModal } from "../../DeviceManagement/Devices/ActionModals/ShowConfigVersionModal";
import { ShowDeviceDashboardModal } from "../../DeviceManagement/Devices/ActionModals/ShowDeviceDashboardModal";
import { RemoteShellModal } from "../../DeviceManagement/Devices/ActionModals/RemoteShellModal";
import { useUser } from "../../../../context/User.context";
import ActionV3Button from "../ActionsV3/ActionButton";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  SelectDevicesPerPage,
  StyledButtonWithBorder,
} from "../../DeviceManagement/Devices/Devices";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer, FlexContainer } from "../../../common/ActionsUtils";
import {
  ButtonPosition,
  NestedDropdownOptions,
  OperationsButtonIcon,
  checkLogs,
  convertDecimalToRoman,
  extractEmail,
  getObjectDepth,
} from "../util";
import Toggle from "../../../common/Toggle";
import { useHistory, useRouteMatch } from "react-router-dom";
import ViewPayloadModal from "./ViewPayloadModal";
import { ThinDivider } from "../../Dashboards/Panel/util";
import ActionFilterNestedDropdown from "../ActionsV3/ActionFilterNestedDropdown";
import { AddFiltersButton } from "../NewAction/PhaseContent";
import {
  NewActionLabelContainer,
  StyledNonBoldHeader,
} from "../NewAction/NewAction";
import SelectedFilter from "../ActionsV3/SelectedFilter";
import { getButtonPosition } from "./LiveActions";
import ActionLogModal from "../../DeviceManagement/Devices/ActionLogModal";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";

interface DeviceV3OverviewProps {
  actionIDParam: string | null;
  deviceIDParam: string | null;
  fetchActionData?: (actionID?: number) => Promise<void>;
}

const DeviceOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
`;

const AuditActionTable = styled(Table)`
  & tbody tr {
    td {
      padding: 2rem 1rem !important;
    }
  }
`;

const AuditActionTableRow = styled(Table.Row)`
  cursor: pointer;
  &.selected {
    background-color: ${({ theme }) =>
      theme.colors["action-selected-table-background"]};
    color: ${({ theme }) => theme.colors["action-selected-table-text-color"]};
  }
`;

export default function DeviceV3Overview(props: DeviceV3OverviewProps) {
  const { user } = useUser();
  const serial_metadata = user?.["tenant-settings"]?.["serial-key"] ?? "";

  const history = useHistory();
  const { path } = useRouteMatch();
  let abortController = new AbortController();
  const allDeviceActionControllerRef = useRef<AbortController | null>(null);
  const actionsFilterOptionsControllerRef = useRef<AbortController | null>(
    null
  );

  const recreateAbortController = (controllerRef) => {
    if (controllerRef.current) {
      controllerRef.current.abort(); // Abort any ongoing operation
    }
    controllerRef.current = new AbortController(); // Create a new controller for the next operation
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<Device>();

  const [streams, setStreams] = useState<Record<string, string[]>>({});
  const [dashboards, setDashboards] = useState<any[]>();

  const [deviceStatus, setDeviceStatus] = useState<string>();
  const [permissions, setPermissions] = useState<Permission>();
  const [metadataKeys, setMetadataKeys] = useState<string[]>();
  const [editableMetadataKeys, setEditableMetadataKeys] =
    useState<Set<string>>();
  const [deviceID, setDeviceID] = useState<number>(
    parseInt(props.deviceIDParam ?? "")
  );
  const [lastAction, setLastAction] = useState<LastActionType>();
  const [allowedActions, setAllowedActions] = useState<string[] | "all">();
  const [stateKeys, setStateKeys] = useState<string[]>();

  const [showConfigVersion, setShowConfigVersion] = useState<boolean>(false);
  const [remoteShellDeviceID, setRemoteShellDeviceID] = useState<number>(0);

  const [openActivateConfirmationModal, setOpenActivateConfirmationModal] =
    useState<boolean>(false);
  const [openShowStreamsModal, setOpenShowStreamsModal] =
    useState<boolean>(false);
  const [openShowConfigVersionModal, setOpenShowConfigVersionModal] =
    useState<boolean>(false);
  const [openShowDeviceDashboardModal, setOpenShowDeviceDashboardModal] =
    useState<boolean>(false);

  const [historicalActions, setHistoricalActions] = useState<
    DeviceActionType[]
  >([]);
  const [historicalActionPageNumber, setHistoricalActionPageNumber] =
    useState<number>(1);
  const [historicalActionPageLimit, setHistoricalActionPageLimit] =
    useState<number>(10);
  const [historicalActionTotalPage, setHistoricalActionTotalPage] =
    useState<number>(1);
  const [historicalActionInputPageNumber, setHistoricalActionInputPageNumber] =
    useState<number>(0);
  const [historicalActionLoading, setHistoricalActionLoading] =
    useState<boolean>(false);

  const [showIncomplete, setShowIncomplete] = useState<boolean>(false);
  const [showPayload, setShowPayload] = useState(false);
  const [selectedAction, setSelectedAction] = useState<DeviceActionType>(
    historicalActions[0]
  );
  const [sortColumn, setSortColumn] = useState<string>("action_id");
  const [sortDirection, setSortDirection] = useState<
    "descending" | "ascending" | undefined
  >("descending");

  // ------- Filters related state starts -------
  const selectedFilterKey = useRef("");
  const [users, setUsers] = useState<{ name: string; email: string }[]>([]);
  const [nestedDropdownOptions, setNestedDropdownOptions] = useState<
    NestedDropdownOptions[]
  >([]);
  const [filters, setFilters] = useState<Filters>({});
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true);

  const [filtersPageNumber, setFiltersPageNumber] = useState<number>(1);

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showInsideFilterDropdown, setShowInsideFilterDropdown] =
    useState<boolean>(false);

  const [infiniteLoading, setInfiniteLoading] = useState<boolean>(true);

  const [depth, setDepth] = useState(1);
  const [currentDepth, setCurrentDepth] = useState<number>(0);

  // determining static position of button so as to support static dropdown even if button moves
  // refer relevant function (getButtonPosition)
  const [addFiltersButtonPosition, setAddFiltersButtonPosition] =
    useState<ButtonPosition>();
  // -------- Filters related state ends -------

  const [detailedStreamsList, setDetailedStreamsList] =
    useState<FetchStreamsAPIResponse>({});

  const [theme, setTheme] = useState<any>();

  const getStreamList = async () => {
    try {
      const res = filterTableInfo(await fetchTableInfo());
      setStreams(res);
    } catch (e) {
      console.log(e);
    }
  };

  const getDashboardList = async () => {
    try {
      const res = await fetchAllDashboards();
      setDashboards(res);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSpecificDevice = async () => {
    abortController.abort();
    abortController = new AbortController();
    if (props.deviceIDParam && props.actionIDParam)
      await fetchDeviceWithSpecificAction(
        parseInt(props.deviceIDParam),
        parseInt(props.actionIDParam),
        abortController.signal
      ).then((deviceData) => {
        setDevice(deviceData);
        setPermissions(user?.role?.permissions);
        setMetadataKeys(user?.role?.permissions?.viewMetadata as string[]);
        setEditableMetadataKeys(
          new Set(user?.role?.permissions?.editMetadata || [])
        );

        setDeviceStatus(deviceData["status"]);

        setDeviceID(deviceData["id"]);
        setLastAction(deviceData["action"]);

        // Choosing not to show sequence in UI table, even though data is available
        // via API.
        if (Object.keys(user.role.permissions.tables)?.length > 0) {
          setStateKeys(
            uniq(
              (
                (user?.role?.permissions?.tables[
                  "device_shadow"
                ] as string[]) || ([] as string[])
              ).filter((key: string) => key !== "sequence")
            )
          );

          setShowConfigVersion(
            uniq(
              (
                (user?.role?.permissions?.tables[
                  "device_shadow"
                ] as string[]) || ([] as string[])
              ).filter((key: string) => key !== "sequence")
            ).includes("config_version")
          );
        }

        setAllowedActions(user?.role?.permissions?.allowedActions);

        setTheme(user?.settings?.theme ?? "dark");
        setLoading(false);
      });
  };

  const toggleSortDirection = (currentDirection) =>
    currentDirection === "ascending" ? "desc" : "asc";

  const onHeaderClick = (newSortColumn) => {
    // Check if the table is not loading, and if the new sorting column is different from the current one
    if (!historicalActionLoading) {
      const isSameColumn = sortColumn === newSortColumn;
      // If the new sorting column is the same as the current one, toggle the sorting direction
      const newSortDirection = isSameColumn
        ? toggleSortDirection(sortDirection)
        : "asc";

      // Update state
      setSortColumn(newSortColumn);
      setSortDirection(newSortDirection === "asc" ? "ascending" : "descending");
      setHistoricalActionPageNumber(1);

      // Fetch new sorted data with the immediate new sorting parameters
      if (deviceID) {
        fetchAllDeviceActionsFn(
          deviceID,
          showIncomplete,
          historicalActionPageNumber,
          historicalActionPageLimit,
          newSortColumn,
          newSortDirection
        );
      }
    }
  };

  // Adjust fetchAllDeviceActionsFn to accept new parameters for sorting
  const fetchAllDeviceActionsFn = useCallback(
    async (
      deviceID: number,
      incomplete: boolean,
      activePage: number,
      pageLimit: number,
      sortColumnps?: string,
      sortDirectionps?: "asc" | "desc"
    ) => {
      setHistoricalActionLoading(true);
      recreateAbortController(allDeviceActionControllerRef);
      const signal = allDeviceActionControllerRef?.current?.signal;
      try {
        if (deviceID) {
          const res = await fetchAllDeviceActions(
            deviceID,
            incomplete,
            activePage,
            pageLimit,
            sortColumnps ?? sortColumn,
            sortDirectionps
              ? sortDirectionps
              : sortDirection === "ascending"
                ? "asc"
                : "desc",
            filters,
            signal
          );
          setHistoricalActions(res.device_actions);
          setHistoricalActionTotalPage(Math.ceil(res.count / pageLimit));
          setHistoricalActionLoading(false);
        }
      } catch (e: any) {
        console.log(e);
        if (e.name !== "AbortError") {
          setHistoricalActionLoading(false);
        }
      }
    },
    [filters, sortColumn, sortDirection]
  );

  // ---- Filters related functions starts ----
  const fetchAllOfTheUsers = async () => {
    const res = await fetchAllUsers();
    let userArray: { name: string; email: string }[] = [];
    Object.values(res.result).forEach((user) =>
      userArray.push({ name: user.name, email: user.email })
    );
    setUsers(userArray);
  };

  // update filters state
  const onFilterChange = useCallback(
    (name: string, values: string[]) => {
      let currentFilters = { ...filters };

      if (values?.length > 0) {
        currentFilters[name] =
          name === "creators"
            ? (() => {
                // values received contains [name (email)]
                // need to extract email
                const extractedEmails = values.map((value) =>
                  extractEmail(value)
                );
                // reconfirming if emails are legit
                return users
                  .filter((user) => extractedEmails.includes(user.email))
                  .map((user) => user.email);
              })()
            : values;
        // if filterName has been received with empty values, meaning filter has been removed
      } else delete currentFilters[name];

      // update filters
      setFilters(currentFilters);
    },
    [filters, users]
  );

  const debouncedFetch = useRef(
    // Call the fetch function after 1 second of inactivity, means if the value of filters doesn't change for 1 second
    debounce(
      async (
        filters: Filters,
        users: { name: string; email: string }[],
        showIncomplete: boolean
      ) => {
        recreateAbortController(actionsFilterOptionsControllerRef);
        const signal = actionsFilterOptionsControllerRef?.current?.signal;
        try {
          setFiltersLoading(true);
          const res = await fetchAllDevicesActionsFiltersOptions(
            props.deviceIDParam ? parseInt(props.deviceIDParam) : 0,
            1,
            showIncomplete,
            filters,
            signal
          );

          const array: NestedDropdownOptions[] = Object.keys(res).map(
            (filterName) => {
              // Convert actions-ids to actions_ids
              const formattedFilterName = filterName.replace(/-/g, "_");

              const emailList = res[filterName] as string[];

              const prevOptions = nestedDropdownOptions
                .find((option) => option.label === formattedFilterName)
                ?.children?.map((value) => ({
                  text: value.text,
                  value: value.value,
                }));

              // Custom display for creators
              // Format - [name (email)]
              const items =
                formattedFilterName === "creators"
                  ? users
                      .filter((user) => emailList.includes(user.email))
                      .map((user) => ({
                        text: `${user.name} (${user.email})`,
                        value: user.email,
                      }))
                  : res[filterName].map((value) => ({ text: value, value }));

              return {
                label: formattedFilterName,
                children: prevOptions ? [...prevOptions, ...items] : items,
              };
            }
          );

          // reset page when filters update
          setHistoricalActionPageNumber(1);
          setNestedDropdownOptions(array);

          // calculate depth of the object being used as dropdown options
          // this is to set back buttons and such stuff
          setDepth(array?.length ? getObjectDepth(array) - 2 : 1);
          setFiltersLoading(false);
        } catch (e: any) {
          console.log(e);
          if (e.name !== "AbortError") {
            setFiltersLoading(false);
          }
        }
      },
      1000
    )
  );

  const resetFilters = () => {
    setShowFilterDropdown(false);
    setShowInsideFilterDropdown(false);
    selectedFilterKey.current = "";
    setHistoricalActionPageNumber(1);
    setCurrentDepth(0);
    setFilters({});
  };

  // function exclusive to infinite scrolling
  const loadMoreFilters = useCallback(async () => {
    if (deviceID)
      try {
        setFiltersPageNumber((page) => page + 1);

        const res = await fetchAllDevicesActionsFiltersOptions(
          deviceID,
          filtersPageNumber + 1, // state update is async
          showIncomplete,
          filters
        );

        /**
         * if received data has an empty array for the currently open filter key in the dropdown
         * means no new data exists - stop infinite scrolling
         */
        if (res[selectedFilterKey.current.replace(/_/g, "-")]?.length === 0)
          setInfiniteLoading(false);
        else setInfiniteLoading(true);

        const array: NestedDropdownOptions[] = Object.keys(res).map(
          (filterName) => {
            // Convert actions-ids to actions_ids
            const formattedFilterName = filterName.replace(/-/g, "_");

            const emailList = res[filterName] as string[];

            /**
             * Calculated to get currently displayed data
             * for the currently open filter key in the dropdown
             */
            const prevOptions = nestedDropdownOptions
              .find((option) => option.label === formattedFilterName)
              ?.children?.map((value) => ({
                text: value.text,
                value: value.value,
              }));

            // Custom display for creators
            // Format - [name (email)]
            const items =
              formattedFilterName === "creators"
                ? users
                    .filter((user) => emailList.includes(user.email))
                    .map((user) => ({
                      text: `${user.name} (${user.email})`,
                      value: user.email,
                    }))
                : res[filterName].map((value) => ({ text: value, value }));

            return {
              label: formattedFilterName,
              children: prevOptions ? [...prevOptions, ...items] : items,
            };
          }
        );

        setNestedDropdownOptions(array);
      } catch (e) {
        console.log(e);
      }
  }, [
    filtersPageNumber,
    nestedDropdownOptions,
    filters,
    users,
    deviceID,
    showIncomplete,
  ]);

  // ---- Filters related functions ends ----

  const handleConfirmMarkAllComplete = () => {
    // call the function that marks all device actions as complete
    handleMarkAllDeviceActions(deviceID);
  };

  const handleMarkAllDeviceActions = async (deviceID) => {
    try {
      if (deviceID) {
        let res = await completeAllActionsForDevices({
          id: [`${deviceID}`],
        });
        if (res && res?.count !== undefined) {
          beamtoast.success(`Marked all actions as complete`);
        }
        await fetchSpecificDevice();
        await fetchAllDeviceActionsFn(
          deviceID,
          showIncomplete,
          historicalActionPageNumber,
          historicalActionPageLimit
        );
        if (props.fetchActionData && props.actionIDParam) {
          await props.fetchActionData(parseInt(props.actionIDParam));
        }
      }
    } catch (e) {
      console.log(e);
      beamtoast.error("Failed to mark all actions as complete.");
    }
  };

  const downloadFile = async (data: any, key: string) => {
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/plain",
    });

    element.href = URL.createObjectURL(file);
    element.download = key;
    document.body.appendChild(element);

    setTimeout(() => element.click());
  };

  const downloadCertificatesFn = async (id: number) => {
    const res = await downloadCertificates(id);
    await downloadFile(JSON.stringify(res), `device_${id}.json`);
  };

  const setRemoteShellDeviceId = (deviceId: number) => {
    setRemoteShellDeviceID(deviceId);
  };

  const changeDeviceStatusFn = async (id: number, status: string) => {
    try {
      setLoading(true);
      await changeDeviceStatus(id, status);
      await fetchSpecificDevice();
      beamtoast.success(`Device status set to ${status}`);
    } catch (e) {
      beamtoast.error("Failed to change device status");
      console.error("Error in changeDeviceStatus: ", e);
    }
  };

  const handlePaginationInputChange = (event) => {
    const newValue = event.target.value;

    setHistoricalActionInputPageNumber(newValue);
  };

  const getInputActivePage = (newValue: number) => {
    let pageNumber =
      newValue > 0
        ? newValue > historicalActionTotalPage
          ? historicalActionTotalPage
          : newValue
        : 1;

    return pageNumber;
  };

  const handlePaginationInputKeyDown = async (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(historicalActionInputPageNumber?.toString())) {
        await onHistoricalActionPageChange(event, {
          activePage: getInputActivePage(historicalActionInputPageNumber),
          totalPages: Math.ceil(
            historicalActionTotalPage / historicalActionPageLimit
          ),
        });
        setHistoricalActionInputPageNumber(0);
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  const changeHistoryActionPerPage = async (event, data) => {
    const newValue = data.value;
    setHistoricalActionPageLimit(newValue);
    setHistoricalActionPageNumber(1);
    try {
      if (deviceID) {
        window.localStorage.setItem("historicalActionsPerPage", newValue);
        await fetchAllDeviceActionsFn(deviceID, showIncomplete, 1, newValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onHistoricalActionPageChange = async (
    event: MouseEvent,
    data: PaginationProps
  ) => {
    const activePage = data.activePage as number;
    setHistoricalActionPageNumber(activePage);

    try {
      if (deviceID) {
        await fetchAllDeviceActionsFn(
          deviceID,
          showIncomplete,
          activePage,
          historicalActionPageLimit
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleMarkAction = async (
    actionID: string,
    passedDeviceID?: number
  ) => {
    try {
      if (actionID) {
        // If deviceID is not provided, mark all actions as complete and if deviceID is provided, mark only that action as complete.
        await markActionAsCompleted(
          actionID,
          passedDeviceID
            ? { metadata: { id: [String(passedDeviceID)] } }
            : { all: true }
        );
        beamtoast.success(
          `Marked action ${actionID} as completed ${passedDeviceID ? `for device(id: ${passedDeviceID})` : "for all devices"}`
        );
        await fetchSpecificDevice();
        await fetchAllDeviceActionsFn(
          deviceID,
          showIncomplete,
          historicalActionPageNumber,
          historicalActionPageLimit
        );
        if (props.fetchActionData && props.actionIDParam) {
          await props.fetchActionData(parseInt(props.actionIDParam));
        }
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(`Failed to mark action ${actionID} as completed`);
    }
  };

  const handleConfirmMarkComplete = (actionId: string, deviceID?: number) => {
    if (user.role.permissions.allowMarkActionAsCompleted)
      handleMarkAction(actionId, deviceID);
  };

  useEffect(() => {
    if (user.email !== "") {
      getStreamList();
      getDashboardList();
      fetchSpecificDevice();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getDetailedStreamList() {
      try {
        const res = await fetchAllStreamsWithDetails();
        setDetailedStreamsList(res);
      } catch (e) {
        console.log(e);
      }
    }

    const historicalActionsCountPerPage: number = parseInt(
      window.localStorage.getItem("historicalActionsPerPage") ?? "10"
    );
    setHistoricalActionPageLimit(historicalActionsCountPerPage);
    fetchAllOfTheUsers();
    getDetailedStreamList();
  }, []);

  useEffect(() => {
    // Store the current debounced fetch function in a ref
    const currentDebouncedFetch = debouncedFetch.current;
    // Cleanup function to cancel the debounced call when the component unmounts
    return () => currentDebouncedFetch.cancel();
  }, []);

  useEffect(() => {
    if (users?.length === 0) return;
    debouncedFetch.current(filters, users, showIncomplete);
  }, [filters, users, showIncomplete]);

  useEffect(() => {
    if (deviceID) {
      setHistoricalActionPageNumber(1);
      fetchAllDeviceActionsFn(
        deviceID,
        showIncomplete,
        1,
        historicalActionPageLimit
      );
    }
  }, [deviceID, filters, showIncomplete]); // eslint-disable-line react-hooks/exhaustive-deps

  return device && !loading ? (
    <DeviceOverviewContainer>
      {deviceID && deviceStatus && streams && dashboards && (
        <>
          <ActivateDeviceConfirmationModal
            deviceId={deviceID}
            deviceStatus={deviceStatus}
            changeDeviceStatus={changeDeviceStatusFn}
            isOpen={openActivateConfirmationModal}
            close={() => setOpenActivateConfirmationModal(false)}
          />
          <ShowDeviceStreamsModal
            deviceId={deviceID}
            isOpen={openShowStreamsModal}
            close={() => setOpenShowStreamsModal(false)}
            streams={streams}
            detailedStreamsList={detailedStreamsList}
          />
          <ShowConfigVersionModal
            configVersion={device["state"]["config_version"]}
            isOpen={openShowConfigVersionModal}
            close={() => setOpenShowConfigVersionModal(false)}
            theme={theme}
          />
          <ShowDeviceDashboardModal
            user={user}
            deviceId={deviceID}
            isOpen={openShowDeviceDashboardModal}
            dashboards={dashboards}
            close={() => setOpenShowDeviceDashboardModal(false)}
          />
        </>
      )}

      {remoteShellDeviceID ? (
        <RemoteShellModal
          deviceId={remoteShellDeviceID}
          isOpen={remoteShellDeviceID > 0}
          close={() => setRemoteShellDeviceID(-1)}
          deviceSerialMetadata={
            device?.["-serial_metadata"]?.[serial_metadata] ?? ""
          }
        />
      ) : (
        <></>
      )}

      <CardContainer marginBottom="25px">
        <h1
          style={{
            fontSize: "32px",
            fontWeight: 700,
            textAlign: "left",
            marginBottom: "32px",
          }}
        >
          Device Overview
        </h1>

        <StyledHeader
          as="h3"
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            width: "fit-content",
          }}
        >
          {serial_metadata ? (
            <Popup
              position="top center"
              inverted
              trigger={
                <div>
                  {`#${capitalizeFirstLetter(serial_metadata)}: ${device?.["-serial_metadata"]?.[serial_metadata]}`}
                </div>
              }
              content={`Id: ${deviceID}`}
            />
          ) : (
            `Device ID: ${deviceID}`
          )}
        </StyledHeader>

        {device &&
          permissions &&
          lastAction &&
          metadataKeys &&
          editableMetadataKeys &&
          deviceID && (
            <Grid columns={3} divided>
              <DeviceOverview
                stateKeys={stateKeys ?? []}
                device={device}
                deviceId={deviceID}
                lastAction={lastAction}
                metadataKeys={metadataKeys}
                enablePinMetadata={false}
                editableMetadataKeys={editableMetadataKeys}
                specificAction
              />
            </Grid>
          )}

        <StyledHeader
          as="h3"
          style={{ marginTop: "40px", marginBottom: "20px" }}
        >
          Device Operations
        </StyledHeader>

        <Grid>
          <StyledGridColumn
            style={{ justifyContent: "flex-start", gap: "12px" }}
            width={16}
          >
            <StyledButtonWithBorder
              onClick={() => {
                if (deviceStatus === "active" && deviceID)
                  downloadCertificatesFn(deviceID);
                else {
                  beamtoast.error(
                    "Cannot download the configuration for a deactivated device."
                  );
                }
              }}
            >
              <Icon name="download" /> <span>Download Config</span>
            </StyledButtonWithBorder>

            {allowedActions?.includes("launch_shell") && (
              <StyledButtonWithBorder
                onClick={() => {
                  if (deviceID) setRemoteShellDeviceId(deviceID);
                }}
              >
                <Icon name="terminal" /> <span>Remote Shell</span>
              </StyledButtonWithBorder>
            )}
            {/* Show only if config_version is in device_shadow */}
            {showConfigVersion && (
              <StyledButtonWithBorder
                onClick={() => setOpenShowConfigVersionModal(true)}
              >
                <Icon name="configure" /> <span>Config Version</span>
              </StyledButtonWithBorder>
            )}
            {Object.keys(user?.role?.permissions?.tables)?.length > 0 && (
              <StyledButtonWithBorder
                onClick={() => setOpenShowStreamsModal(true)}
              >
                <Icon name="table" /> <span>Streams</span>
              </StyledButtonWithBorder>
            )}
            {Object.entries(streams).filter(([stream, fields]) =>
              checkLogs(stream)
            )?.length > 0 && (
              <StyledButtonWithBorder
                onClick={() =>
                  window.open(
                    `/projects/${getTenantFromURL()}/actions/logs/device-log?device_id=${deviceID}`,
                    "_blank"
                  )
                }
              >
                <Icon name="file alternate outline" /> <span>Logs</span>
              </StyledButtonWithBorder>
            )}
            <StyledButtonWithBorder
              onClick={() => setOpenShowDeviceDashboardModal(true)}
            >
              <Icon name="dashboard" /> <span>Device Dashboards</span>
            </StyledButtonWithBorder>
            <StyledButtonWithBorder
              onClick={() => setOpenActivateConfirmationModal(true)}
            >
              <Icon
                name={
                  deviceStatus === "active" ? "ban" : "check circle outline"
                }
              />{" "}
              <span>
                {deviceStatus === "active" ? "Deactivate" : "Activate"}
              </span>
            </StyledButtonWithBorder>
          </StyledGridColumn>
        </Grid>
      </CardContainer>

      <CardContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: "32px",
              fontWeight: 700,
              textAlign: "left",
              marginBottom: "2px",
            }}
          >
            Historical Actions
          </h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div>Show Incomplete Actions</div>
            <div>
              <Toggle
                id="sortByIncompleteActions"
                checked={showIncomplete}
                onChange={() => {
                  if (deviceID) {
                    setShowIncomplete(!showIncomplete);
                    setFilters({});
                  }
                }}
                style={{
                  top: "4px",
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "16px",
            marginBottom: "6px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {nestedDropdownOptions && nestedDropdownOptions?.length !== 0 && (
            <>
              <NewActionLabelContainer style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      minHeight: "35px",
                    }}
                  >
                    <div
                      className="dashboard-header"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <FlexContainer>
                        <div>
                          <ActionV3Button
                            onClick={() => {
                              getButtonPosition(
                                "filter-actions-button",
                                setAddFiltersButtonPosition
                              );
                              setShowInsideFilterDropdown(true);
                              setShowFilterDropdown(false);
                            }}
                            disabled={filtersLoading}
                            border_style={"dashed"}
                            label={"Filters"}
                            icon={"filter"}
                            margin_left="0px"
                            id="filter-actions-button"
                          />
                          <div style={{ position: "relative" }}>
                            {showInsideFilterDropdown && (
                              <ActionFilterNestedDropdown
                                setShowDropdown={setShowInsideFilterDropdown}
                                depth={depth}
                                onClick={onFilterChange}
                                nestedDropdownOptions={nestedDropdownOptions}
                                filters={filters}
                                currentDepth={currentDepth}
                                setCurrentDepth={setCurrentDepth}
                                selectedFilterKey={selectedFilterKey}
                                infiniteLoading={infiniteLoading}
                                setInfiniteLoading={setInfiniteLoading}
                                loadMore={loadMoreFilters}
                                search
                              />
                            )}
                          </div>
                        </div>
                      </FlexContainer>
                    </div>
                  </div>
                  {Object.keys(filters)?.length !== 0 && (
                    <div
                      style={{
                        alignSelf: "center",
                      }}
                    >
                      <StyledNonBoldHeader
                        as="h3"
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                          fontSize: "1.1rem",
                          whiteSpace: "nowrap",
                        }}
                        className={`${
                          Object.keys(filters)?.length === 0
                            ? "color-disabled"
                            : "selectable-item underline hover-underline color-blue"
                        }`}
                        onClick={() => {
                          resetFilters();
                        }}
                        id="action-filter-clear-all"
                      >
                        Clear All
                      </StyledNonBoldHeader>
                    </div>
                  )}
                </div>
              </NewActionLabelContainer>
              {Object.keys(filters)?.length !== 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      width: "100%",
                    }}
                  >
                    {Object.keys(filters).map((key, idx) => (
                      <SelectedFilter
                        key={idx}
                        filterName={key}
                        filterValues={
                          key === "creators"
                            ? users
                                .filter((user) =>
                                  filters?.creators?.includes(user.email)
                                )
                                .map((user) => user.name)
                            : filters[key]
                        }
                        filterNameIcon={"file"}
                        filterValueIcon={"info circle"}
                        onCrossClick={onFilterChange}
                        multiple
                      />
                    ))}

                    <AddFiltersButton
                      name="plus"
                      id={"action-add-filters-button"}
                      onClick={() => {
                        setShowFilterDropdown(true);
                        getButtonPosition(
                          "action-add-filters-button",
                          setAddFiltersButtonPosition
                        );
                      }}
                    />
                    <div style={{ position: "relative" }}>
                      {showFilterDropdown && (
                        <ActionFilterNestedDropdown
                          setShowDropdown={setShowFilterDropdown}
                          depth={depth}
                          onClick={onFilterChange}
                          nestedDropdownOptions={nestedDropdownOptions}
                          filters={filters}
                          currentDepth={currentDepth}
                          setCurrentDepth={setCurrentDepth}
                          selectedFilterKey={selectedFilterKey}
                          parentButtonPosition={addFiltersButtonPosition}
                          infiniteLoading={infiniteLoading}
                          setInfiniteLoading={setInfiniteLoading}
                          loadMore={loadMoreFilters}
                          search
                        />
                      )}
                    </div>
                    <Divider />
                  </div>
                  <ThinDivider />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {(!historicalActionLoading || !filtersLoading) &&
          historicalActions?.length > 0 &&
          permissions?.allowMarkActionAsCompleted && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "16px",
              }}
            >
              <ConfirmationModal
                prefixContent="Mark All Actions as Complete"
                expectedText={"Yes"}
                onConfirm={handleConfirmMarkAllComplete}
                trigger={
                  <ActionV3Button
                    onClick={() => {}}
                    label={"Mark All Complete"}
                    margin_left={"20px"}
                    padding={"0.5rem 1rem"}
                    icon={"check circle"}
                  />
                }
                message={
                  <ConfirmationModalMessageNonDelete
                    name={"Mark All Actions as Complete"}
                    expectedText={"Yes"}
                    type={""}
                    specialMessage={`This will mark all Actions triggered on this Device(${serial_metadata ? `${serial_metadata}: ${device?.["-serial_metadata"]?.[serial_metadata]}` : `id: ${deviceID}`}) which are not in completed/failed states as completed.`}
                  />
                }
              />
            </div>
          )}
        <Grid style={{ marginBottom: "20px" }}>
          <ViewPayloadModal
            isOpen={showPayload}
            onClose={() => setShowPayload(false)}
            actionId={selectedAction?.action_id as number}
          />
          <Grid.Row>
            <Grid.Column>
              <AuditActionTable
                id="device_historical_actions-list"
                selectable
                sortable
                fixed
              >
                <Table.Header>
                  <Table.Row textAlign={"center"}>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "action_id" ? sortDirection : undefined
                      }
                      onClick={() => onHeaderClick("action_id")}
                      width={1}
                    >
                      ID
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortColumn === "type" ? sortDirection : undefined}
                      onClick={() => onHeaderClick("type")}
                      width={2}
                    >
                      Action Type
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "user_name" ? sortDirection : undefined
                      }
                      onClick={() => onHeaderClick("user_name")}
                      width={2}
                    >
                      Created By
                    </Table.HeaderCell>

                    <Table.HeaderCell
                      sorted={
                        sortColumn === "updated_at" ? sortDirection : undefined
                      }
                      onClick={() => onHeaderClick("updated_at")}
                      width={3}
                    >
                      Timestamps
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell
                          sorted={sortColumn === "type" ? sortDirection : undefined}
                          onClick={() => {
                            let isAsc = sortColumn === 'type' && sortDirection === 'ascending';
                            setSortColumn('type');
                            setSortDirection(isAsc ? 'descending' : 'ascending');
                            fetchAllDeviceActionsFn(deviceID, showIncomplete);
                          }}
                        >Approved By</Table.HeaderCell> */}
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "status" ? sortDirection : undefined
                      }
                      onClick={() => onHeaderClick("status")}
                      width={2}
                    >
                      Last Status
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "errors" ? sortDirection : undefined
                      }
                      onClick={() => onHeaderClick("errors")}
                      width={3}
                    >
                      Errors
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>Options</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!historicalActionLoading && historicalActions?.length > 0 ? (
                    historicalActions.map((action, key) => (
                      <AuditActionTableRow textAlign={"center"} key={key}>
                        <Table.Cell>
                          <div>{`${action.action_id}`}</div>
                          <div>
                            {action.phase !== -1 &&
                              `Phase ${convertDecimalToRoman(action?.phase + 1)}`}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <TextWithToolTip text={action.type} />
                        </Table.Cell>
                        <Table.Cell>
                          <TextWithToolTip
                            text={action.user_name}
                            textList={
                              action.user_email ? [action.user_email] : []
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <div>
                            Created At:{" "}
                            {new Date(action.created_at)?.toLocaleString(
                              "en-GB"
                            )}
                          </div>
                          <div>
                            Updated At:{" "}
                            {new Date(action.updated_at)?.toLocaleString(
                              "en-GB"
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <TextWithToolTip text={action.status} />
                        </Table.Cell>
                        <Table.Cell>
                          <TextWithToolTip
                            text={
                              action.errors && action.errors?.length > 0
                                ? Array.isArray(action.errors)
                                  ? action.errors.join(", ")
                                  : action.errors
                                : "None"
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Popup
                              content="View Payload"
                              position="top center"
                              inverted
                              trigger={
                                <div>
                                  <OperationsButtonIcon
                                    disabled={
                                      action?.payload_type === "none" &&
                                      action.type !== "send_file" &&
                                      action.type !== "send_script"
                                    }
                                    name="file code outline"
                                    onClick={() => {
                                      setSelectedAction(action);
                                      setShowPayload(true);
                                    }}
                                  />
                                </div>
                              }
                            />
                            {user.role.permissions
                              .allowMarkActionAsCompleted && (
                              <Popup
                                content="Mark Action on device as completed"
                                position="top center"
                                inverted
                                trigger={
                                  <div>
                                    <ConfirmationModal
                                      prefixContent="Mark Device Action as Complete"
                                      expectedText={String(action.action_id)}
                                      onConfirm={() => {
                                        handleConfirmMarkComplete(
                                          String(action.action_id),
                                          deviceID
                                        );
                                      }}
                                      trigger={
                                        <OperationsButtonIcon
                                          disabled={
                                            ["completed", "failed"].includes(
                                              action.status.toLowerCase()
                                            ) ||
                                            action?.type === "cancel_action"
                                          }
                                          name="check circle outline"
                                        />
                                      }
                                      message={
                                        <ConfirmationModalMessageNonDelete
                                          name={
                                            "Mark Device Action as Complete"
                                          }
                                          expectedText={String(
                                            action.action_id
                                          )}
                                          type={""}
                                          specialMessage={`This will mark the Action(id: ${action.action_id}, type: ${action.type}) for Device(id: ${deviceID}) as completed.`}
                                        />
                                      }
                                    />
                                  </div>
                                }
                              />
                            )}
                            {user.role.permissions
                              .allowMarkActionAsCompleted && (
                              <Popup
                                content="Mark Action as completed"
                                position="top center"
                                inverted
                                trigger={
                                  <div>
                                    <ConfirmationModal
                                      prefixContent="Mark Action as Complete"
                                      expectedText={String(action.action_id)}
                                      onConfirm={() => {
                                        handleConfirmMarkComplete(
                                          String(action.action_id)
                                        );
                                      }}
                                      trigger={
                                        <OperationsButtonIcon
                                          disabled={
                                            action?.type === "cancel_action" ||
                                            action?.action_status === "FINISHED"
                                          }
                                          name="check square"
                                        />
                                      }
                                      message={
                                        <ConfirmationModalMessageNonDelete
                                          name={"Mark Action as Complete"}
                                          expectedText={String(
                                            action.action_id
                                          )}
                                          type={""}
                                          specialMessage={`This will mark the Action(id: ${action.action_id}, type: ${action.type}) triggered on all Devices which are not in completed/failed states as completed.`}
                                        />
                                      }
                                    />
                                  </div>
                                }
                              />
                            )}
                            <ActionLogModal
                              actionId={action.action_id.toString()}
                              deviceId={deviceID.toString()}
                              actionStatus={action?.status}
                              trigger={
                                <div>
                                  <Popup
                                    content="Action Progress Logs"
                                    position="top center"
                                    inverted
                                    trigger={
                                      <div>
                                        <OperationsButtonIcon
                                          name="arrow alternate circle up outline"
                                          disabled={
                                            !user.role.permissions.allowedActions.includes(
                                              action.type
                                            )
                                          }
                                        />
                                      </div>
                                    }
                                  />
                                </div>
                              }
                            />
                            <Popup
                              content="More details"
                              position="top center"
                              inverted
                              trigger={
                                <div>
                                  <OperationsButtonIcon
                                    name="eye"
                                    onClick={() => {
                                      history.push(
                                        `${path}?action_id=${action.action_id}`
                                      );
                                    }}
                                  />
                                </div>
                              }
                            />
                          </div>
                        </Table.Cell>
                      </AuditActionTableRow>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={"7"}>
                        <div
                          style={{
                            height: "25vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {historicalActionLoading ? (
                            <LoadingAnimation
                              fontSize="1.5rem"
                              loadingText="Loading Historical Actions..."
                            />
                          ) : (
                            <ErrorMessage
                              marginTop="30px"
                              message={"No actions found! "}
                            />
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </AuditActionTable>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {!loading &&
          !historicalActionLoading &&
          historicalActions?.length !== 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: "16px",
              }}
            >
              <StyledPagination
                boundaryRange={0}
                ellipsisItem={null}
                siblingRange={2}
                activePage={historicalActionPageNumber}
                totalPages={historicalActionTotalPage}
                onPageChange={onHistoricalActionPageChange}
              />

              <StyledCardSearchPageInput
                icon="search"
                placeholder="Jump to page..."
                name="activePage"
                min={1}
                onChange={handlePaginationInputChange}
                onKeyDown={handlePaginationInputKeyDown}
                type="number"
                value={
                  historicalActionInputPageNumber
                    ? historicalActionInputPageNumber
                    : ""
                }
              />

              <StyledSecondaryDevicePerPageWidget>
                <MenuItem>Actions per page</MenuItem>
                <MenuItem style={{ padding: "0px" }}>
                  <SelectDevicesPerPage
                    compact
                    selection
                    options={devicesPerPageOptions}
                    value={historicalActionPageLimit}
                    onChange={changeHistoryActionPerPage}
                  />
                </MenuItem>
              </StyledSecondaryDevicePerPageWidget>
            </div>
          )}
      </CardContainer>
    </DeviceOverviewContainer>
  ) : (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="1.5rem"
      loadingText="Loading device data"
    />
  );
}
