import React, { useEffect, useState } from "react";
import {
  WhatsAppNotificationChannel,
  NotificationRule,
} from "../CreateNotificationRule";
import {
  StyledInput,
  StyledLabel,
} from "../../AlertRules/CreateAlertRuleModal";

import { ErrorMessage } from "../../../Settings/roles/CreateOrEditRoleModal";
import { validatePhoneNumber } from "../../../../../util";

type CreateWhatsAppNotificationChannelProps = {
  readonly channel: WhatsAppNotificationChannel;
  readonly setChannel: (channel: WhatsAppNotificationChannel) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly notificationRule: NotificationRule;
};

export default function CreateWhatsAppNotificationChannel(
  props: CreateWhatsAppNotificationChannelProps
) {
  const { setIsValid, channel, setChannel, notificationRule } = props;
  const [validationError, setValidationError] = useState<string | null>(null);
  const [blurred, setBlurred] = useState(false);

  useEffect(() => {
    const channelPhoneNumbers = channel.phoneNumbers.filter(
      (p) => p.length > 0
    );
    const isValid =
      channelPhoneNumbers.length > 0 &&
      notificationRule.alertRuleId !== "" &&
      channel.activationTemplateId !== "" &&
      channel.deactivationTemplateId !== "" &&
      !validationError;

    setIsValid(isValid);
  }, [channel, setIsValid, notificationRule.alertRuleId, validationError]);

  const setPhoneNumbers = (phoneNumberString: string) => {
    const phoneNumbers = phoneNumberString.split(",").map((p) => p.trim());
    const arePhoneNumbersValid = phoneNumbers.every(
      (p) => p === "" || validatePhoneNumber(p)
    );

    if (phoneNumberString.length > 0 && !arePhoneNumbersValid) {
      setValidationError("Invalid phone number format");
    } else {
      setBlurred(false);
      setValidationError(null);
    }

    setChannel({
      ...channel,
      phoneNumbers,
    });
  };

  const handleBlur = () => {
    setBlurred(true);
  };

  const handleFocus = () => {
    if (!validationError) {
      setBlurred(false);
    }
  };

  return (
    <div style={{ marginBottom: "14px" }}>
      <StyledInput labelPosition="left">
        <StyledLabel>Phone Numbers</StyledLabel>
        <input
          value={channel.phoneNumbers}
          onChange={(e) => setPhoneNumbers(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="Comma-separated list of phone numbers"
        />
      </StyledInput>

      {validationError && blurred && (
        <ErrorMessage
          id="validation_message"
          style={{ fontWeight: 700, top: "-12px" }}
        >
          {validationError}
        </ErrorMessage>
      )}

      <StyledInput labelPosition="left">
        <StyledLabel>Activation Template ID</StyledLabel>
        <input
          value={channel.activationTemplateId}
          onChange={(e) =>
            setChannel({ ...channel, activationTemplateId: e.target.value })
          }
          placeholder="Enter WhatsApp activation template ID"
        />
      </StyledInput>
      <StyledInput labelPosition="left">
        <StyledLabel>Deactivation Template ID</StyledLabel>
        <input
          value={channel.deactivationTemplateId}
          onChange={(e) =>
            setChannel({ ...channel, deactivationTemplateId: e.target.value })
          }
          placeholder="Enter WhatsApp deactivation template ID"
        />
      </StyledInput>
    </div>
  );
}
