import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";
import { TimeRange } from "./Datetime/TimeRange";
import { DashboardsInfo } from "./ViewDashboard";

type DashboardLinksProps = {
  readonly currentDashboardType: string;
  readonly dashboardIds: string[];
  readonly allDashboards: DashboardsInfo[];
  readonly timeRange?: TimeRange;
  readonly deviceId?: string;
};

export function DashboardLinks(props: DashboardLinksProps) {
  return (
    <div className="dashboard-links">
      {props.dashboardIds.map((id) => {
        const dashboard = props.allDashboards.find(
          (dashboard) => String(dashboard.id) === id
        );
        const url = new URL(id, window.location.href);
        const queryParams = new URLSearchParams(window.location.search);

        queryParams.forEach((value, key) => url.searchParams.set(key, value));

        if (
          props.currentDashboardType === "fleet" &&
          dashboard?.type === "device"
        ) {
          url.searchParams.delete("id");
        }

        if (props.deviceId) {
          url.searchParams.set("id", props.deviceId);
        }

        if (props.timeRange) {
          const timeRange = JSON.stringify(props.timeRange.serialize());
          url.searchParams.set("timeRange", timeRange);
        }

        if (dashboard) {
          return (
            <Button
              key={id}
              as="a"
              href={url.toString()}
              target="_blank"
              style={{
                marginRight: "8px",
                padding: "9px 12px",
                whiteSpace: "nowrap",
                fontSize: "12px",
              }}
              icon="external"
              secondary
              content={`${dashboard?.title} (${dashboard?.type})`}
            />
          );
        } else {
          return <Fragment key="0"></Fragment>;
        }
      })}
    </div>
  );
}
