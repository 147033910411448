import React, { useEffect, useState, useRef } from "react";
import {
  NotificationRule,
  WebhookNotificationChannel,
} from "../CreateNotificationRule";
import {
  StyledInput,
  StyledLabel,
} from "../../AlertRules/CreateAlertRuleModal";

import { Header, HttpHeaders } from "../HttpHeaders";
import { ErrorMessage } from "../../../Settings/roles/CreateOrEditRoleModal";

type CreateWebhookNotificationChannelProps = {
  readonly channel: WebhookNotificationChannel;
  readonly setChannel: (channel: WebhookNotificationChannel) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly notificationRule: NotificationRule;
};

export default function CreateWebhookNotificationChannel({
  setIsValid,
  channel,
  setChannel,
  notificationRule,
}: CreateWebhookNotificationChannelProps) {
  const [inputValues, setInputValues] = useState<Header[]>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string | null;
  }>({});
  const [blurred, setBlurred] = useState(false);

  const urlInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setInputValues(
      Object.entries(channel.headers).map(([key, value]) => ({ key, value }))
    );
  }, [channel.headers]);

  useEffect(() => {
    const areHeadersValid = (headers: { [key: string]: string }) => {
      // Case: If headers object is empty, return true
      if (Object.keys(headers).length === 0) {
        return true;
      }

      // Special case: If there is exactly one header with both key and value as empty strings return true
      // this is because as default we are receiving this
      if (Object.keys(headers).length === 1 && headers[""] === "") {
        return true;
      }

      // General case: Validate all other headers
      return (
        Object.keys(headers).filter((headerKey) => {
          return headerKey.length === 0 || headers[headerKey].length === 0;
        }).length === 0
      );
    };

    const isValid =
      channel.url.length > 0 &&
      areHeadersValid(channel.headers) &&
      notificationRule.alertRuleId !== "" &&
      Object.values(validationErrors).every((error) => !error);

    setIsValid(isValid);
  }, [
    channel,
    inputValues,
    setIsValid,
    notificationRule.alertRuleId,
    validationErrors,
  ]);

  const setUrl = (url: string) => {
    if (
      url.length > 0 &&
      urlInputRef.current &&
      !urlInputRef.current.checkValidity()
    ) {
      setValidationErrors({ ...validationErrors, url: "Invalid URL format" });
    } else {
      setBlurred(false);
      setValidationErrors({ ...validationErrors, url: null });
    }

    setChannel({
      ...channel,
      url,
    });
  };

  const handleBlur = () => {
    setBlurred(true);
  };

  const handleFocus = () => {
    if (!validationErrors.url && !validationErrors.header) {
      setBlurred(false);
    }
  };

  const setHeaders = (headersArray: Header[]) => {
    setInputValues(headersArray);

    const headerKeys = headersArray.map((header) => header.key.trim());
    const duplicateKeys = headerKeys.filter(
      (key, index) => headerKeys.indexOf(key) !== index
    );

    if (duplicateKeys.length !== 0) {
      setValidationErrors({
        ...validationErrors,
        header: `Headers with the keys '${duplicateKeys.join(", ")}' already exist`,
      });
    } else {
      setValidationErrors({ ...validationErrors, header: null });
    }

    if (duplicateKeys.length === 0) {
      setChannel({
        ...channel,
        headers: headersArray.reduce<{ [key: string]: string }>(
          (acc, header) => {
            acc[header.key] = header.value;
            return acc;
          },
          {}
        ),
      });
    }
  };

  return (
    <>
      <div style={{ marginBottom: "14px" }}>
        <StyledInput style={{ marginBottom: "0px" }} labelPosition="left">
          <StyledLabel>Url</StyledLabel>
          <input
            ref={urlInputRef}
            value={channel.url}
            type="url"
            placeholder="URL"
            onChange={(e) => setUrl(e.target.value)}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        </StyledInput>
        {blurred && validationErrors.url && (
          <ErrorMessage
            id="url_validation_message"
            style={{ fontWeight: 700, top: "0px" }}
          >
            {validationErrors.url}
          </ErrorMessage>
        )}
      </div>

      <div style={{ marginBottom: "24px" }}>
        <HttpHeaders
          headers={inputValues.map((header) => ({
            key: header.key,
            value: header.value,
          }))}
          onChange={setHeaders}
        />
        {validationErrors.header && (
          <ErrorMessage
            id="header_validation_message"
            style={{ fontWeight: 700, top: "5px" }}
          >
            {validationErrors.header}
          </ErrorMessage>
        )}
      </div>
    </>
  );
}
