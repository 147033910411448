import { enableDBCParserByDefault, ShowTabsData } from "../../../../util";

export const showTabsDataConfig: ShowTabsData = {
  device_management: { show: true },
  dashboards: { show: true },
  actions: {
    show: true,
  },
  alerts: {
    show: true,
  },
  dbc_parsers: {
    show: enableDBCParserByDefault() || false,
  },
  device_components: {
    show: false,
  },
};

export const showTabResolver: Record<string, string> = {
  device_management: "Device Management",
  dashboards: "Dashboards",
  actions: "Actions",
  live_actions: "Action Details",
  inventory: "Inventory",
  firmware_files: "Firmware Files",
  json_configurations: "JSON Configurations",
  geofence_configurations: "Geofence Configurations",
  new_action: "New Action",
  alerts: "Alerts",
  alert_notification: "Alert Notification",
  alert_rules: "Alert Rules",
  dbc_parsers: "DBC Parsers",
  device_components: "Device Components",
};
