import React, { useState } from "react";
import { Button, Grid, Icon, Popup, Table } from "semantic-ui-react";
import {
  AlertNotificationRule,
  AlertRule,
  EmailNotificationParams,
  SlackNotificationParams,
  SmsNotificationParams,
  WebhookNotificationParams,
  WhatsAppNotificationParams,
} from "../../../../util";
import { ButtonIcon, DisplayIf, formatDuration } from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import {
  createAlertNotificationRule,
  deleteAlertNotificationRule,
  updateAlertNotificationRule,
} from "../../../../BytebeamClient";
import { beamtoast } from "../../../common/CustomToast";
import styled from "styled-components";
import { useUser } from "../../../../context/User.context";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import { Mixpanel } from "../../common/MixPanel";
import CreateNotificationRuleModal from "./CreateNotificationRule";
import { AlertRuleOperationType } from "../AlertRules/AlertRules";
import moment from "moment";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { CardContainer } from "../../../common/ActionsUtils";

export enum NotificationRuleOperationType {
  Create = "create",
  Update = "update",
}

export enum NotificationModalStepType {
  ChooseNotificationType,
  FillNotificationTypeDetails,
}

interface DeleteAlertNotificationRuleButtonProps {
  readonly onChange: (showLoading: boolean) => void;
  readonly alertRuleLastProcessedAt:
    | Record<number, string | number>
    | undefined;
  readonly notificationRule: AlertNotificationRule;
  readonly alertRuleName: string;
}

function DeleteAlertNotificationRuleButton(
  props: DeleteAlertNotificationRuleButtonProps
) {
  const {
    alertRuleName,
    notificationRule,
    alertRuleLastProcessedAt,
    onChange,
  } = props;
  const channelType = notificationRule.channel_type;
  const isAlertRuleProcessed =
    alertRuleLastProcessedAt &&
    Object.values(alertRuleLastProcessedAt).length > 0;

  return isAlertRuleProcessed ? (
    <Popup
      inverted
      position="top center"
      content="Alert notification can't be deleted once alert rule is processed."
      trigger={
        <ButtonIcon
          link
          name="trash"
          title="Delete Notification Rule"
          disabled={isAlertRuleProcessed}
        />
      }
    />
  ) : (
    <ConfirmationModal
      trigger={
        <ButtonIcon link name="trash" title="Delete Notification Rule" />
      }
      prefixContent="Delete Alert Notification Rule"
      expectedText={`${alertRuleName} - ${channelType}`}
      message={
        <ConfirmationModalMessage
          name={`${alertRuleName} - ${channelType}`}
          type={"Alert Notification Rule"}
          specialMessage="Note that this will also delete any existing notifications for this alert rule."
        />
      }
      onConfirm={async () => {
        try {
          await deleteAlertNotificationRule(notificationRule);
          beamtoast.success(
            `Deleted alert notification rule "${alertRuleName} - ${channelType}"`
          );
        } catch (e) {
          beamtoast.error(
            `Failed to delete alert rule "${alertRuleName} - ${channelType}"`
          );
          console.log(e);
        } finally {
          onChange(true);
        }
      }}
    />
  );
}

const StyleNotificationChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  line-height: 18px;
  gap: 12px;
`;

type AlertNotificationRulesProps = {
  readonly alertRules: AlertRule[];
  readonly onChange: (showLoading: boolean) => void;
};

export default function AlertNotificationRules(
  props: AlertNotificationRulesProps
) {
  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const { alertRules, onChange } = props;

  const [open, setOpen] = useState(false);
  const [notificationRule, setNotificationRule] = useState<
    AlertNotificationRule | undefined
  >();
  const [notificationModalStep, setNotificationModalStep] = useState<number>(
    NotificationModalStepType.ChooseNotificationType
  );
  const [operationType, setOperationType] = useState<string>(
    NotificationRuleOperationType.Create
  );

  const renderNotificationChannel = (
    notificationRule: AlertNotificationRule
  ) => {
    if (notificationRule.channel_type === "slack") {
      const params =
        notificationRule.channel_parameters as SlackNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="slack" style={{ fontSize: "17px", fontWeight: "900" }} />
          <TextWithToolTip text={params.slack_channel} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "email") {
      const params =
        notificationRule.channel_parameters as EmailNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="mail" style={{ fontSize: "15px" }} />
          <TextWithToolTip text={params.emails.join(", ")} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "webhook") {
      const params =
        notificationRule.channel_parameters as WebhookNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="external" style={{ fontSize: "15px" }} />
          <TextWithToolTip text={params.url} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "sms") {
      const params =
        notificationRule.channel_parameters as SmsNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon name="comment" style={{ fontSize: "15px" }} />
          <TextWithToolTip text={params.phone_numbers.join(", ")} />
        </StyleNotificationChannelWrapper>
      );
    } else if (notificationRule.channel_type === "whatsapp") {
      const params =
        notificationRule.channel_parameters as WhatsAppNotificationParams;

      return (
        <StyleNotificationChannelWrapper>
          <Icon
            name="whatsapp"
            style={{ fontSize: "17px", fontWeight: "900" }}
          />
          <TextWithToolTip text={params.phone_numbers.join(", ")} />
        </StyleNotificationChannelWrapper>
      );
    }
  };

  const renderDeleteButton = (
    alertRule: AlertRule,
    notificationRule: AlertNotificationRule
  ) => {
    return (
      <DeleteAlertNotificationRuleButton
        alertRuleLastProcessedAt={alertRule.last_processed_timestamps}
        notificationRule={notificationRule}
        alertRuleName={alertRule.name}
        onChange={onChange}
      />
    );
  };

  const doNotificationRulesExist = () => {
    let exist: boolean = false;

    if (alertRules.length !== 0) {
      for (const alertRule of alertRules) {
        if (alertRule.notification_rules?.length !== 0) {
          exist = true;
          break;
        }
      }
    }

    return exist;
  };

  const handleCreateAlertNotificationRule = async (
    notificationRule: AlertNotificationRule
  ) => {
    const { channel_type: channelType, alert_rule_id: alertRuleId } =
      notificationRule;
    const alertRuleName = alertRules.find(
      (rule) => rule.id === alertRuleId
    )?.name;

    try {
      await createAlertNotificationRule(notificationRule);
      beamtoast.success(
        `Created alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Created Notification Rule", {});
    } catch (e) {
      console.error("Error creating alert notification rule:", e);
      beamtoast.error(
        `Failed to create alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Failure", {
        type: "Alert Notification Rule creation",
        error: JSON.stringify(e instanceof Error ? e.message : e),
      });
    } finally {
      onChange(true);
    }
  };

  const handleEditAlertNotificationRule = async (
    notificationRule: AlertNotificationRule
  ) => {
    const { channel_type: channelType, alert_rule_id: alertRuleId } =
      notificationRule;
    const alertRuleName = alertRules.find(
      (rule) => rule.id === alertRuleId
    )?.name;

    try {
      await updateAlertNotificationRule(notificationRule);
      beamtoast.success(
        `Updated alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Updated Notification Rule", {});
    } catch (e) {
      console.error("Error editing alert notification rule:", e);
      beamtoast.error(
        `Failed to edit alert notification rule "${alertRuleName} - ${channelType}"`
      );
      Mixpanel.track("Failure", {
        type: "Alert Notification Rule editing",
        error: JSON.stringify(e instanceof Error ? e.message : e),
      });
    } finally {
      onChange(true);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setOperationType(NotificationRuleOperationType.Create);
    setNotificationRule(undefined);
  };

  return (
    <CardContainer>
      <Grid>
        <CreateNotificationRuleModal
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleModalClose}
          title={
            operationType === NotificationRuleOperationType.Create
              ? "New Notification Rule"
              : "Edit Notification Rule"
          }
          onSubmit={
            operationType === NotificationRuleOperationType.Create
              ? handleCreateAlertNotificationRule
              : handleEditAlertNotificationRule
          }
          alertRules={alertRules}
          alertNotificationRule={notificationRule}
          notificationModalStep={notificationModalStep}
          operationType={
            operationType === NotificationRuleOperationType.Create
              ? "create"
              : "update"
          }
        />
        <Grid.Row>
          <Grid.Column>
            <DisplayIf cond={permissions.editNotifications}>
              <Button
                id="addNotificationRuleButton"
                primary
                floated="right"
                icon
                labelPosition="left"
                onClick={() => {
                  setOperationType(AlertRuleOperationType.Create);
                  setNotificationModalStep(
                    NotificationModalStepType.ChooseNotificationType
                  );
                  setOpen(true);
                }}
              >
                <Icon name="plus" />
                Notification Rule
              </Button>
            </DisplayIf>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table id="notificationRulesTable" celled fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Alert Name</Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    Notification Channel
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>Interval</Table.HeaderCell>
                  <Table.HeaderCell>Last Notified At</Table.HeaderCell>
                  <Table.HeaderCell>Timezone</Table.HeaderCell>
                  <DisplayIf cond={permissions.editNotifications}>
                    <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                  </DisplayIf>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {alertRules.length !== 0 && doNotificationRulesExist() ? (
                  alertRules.map((alertRule) => {
                    return alertRule.notification_rules?.map(
                      (notificationRule) => {
                        return (
                          <Table.Row key={notificationRule.id}>
                            <Table.Cell>
                              <TextWithToolTip
                                text={notificationRule.id as string}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <TextWithToolTip text={alertRule.name} />
                            </Table.Cell>
                            <Table.Cell>
                              {renderNotificationChannel(notificationRule)}
                            </Table.Cell>
                            <Table.Cell>
                              {formatDuration(
                                notificationRule.interval_seconds
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {notificationRule.last_notified_at
                                ? moment(
                                    notificationRule.last_notified_at
                                  ).fromNow()
                                : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {notificationRule.timezone ?? "UTC"}
                            </Table.Cell>
                            <DisplayIf cond={permissions.editNotifications}>
                              <Table.Cell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "12px",
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <ButtonIcon
                                    link
                                    name="edit"
                                    title="Edit Notification Rule"
                                    onClick={() => {
                                      setNotificationRule(notificationRule);
                                      setOperationType(
                                        NotificationRuleOperationType.Update
                                      );
                                      setNotificationModalStep(
                                        NotificationModalStepType.FillNotificationTypeDetails
                                      );
                                      setOpen(true);
                                    }}
                                  />
                                  {renderDeleteButton(
                                    alertRule,
                                    notificationRule
                                  )}
                                </div>
                              </Table.Cell>
                            </DisplayIf>
                          </Table.Row>
                        );
                      }
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={permissions.editNotifications ? 6 : 5}>
                      <ErrorMessage message={"No Notification Rules found!"} />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CardContainer>
  );
}
