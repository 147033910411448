import { SessionsMetaData } from "./PanelDef";
import React from "react";

import {
  EditComponentProps,
  PanelEditComponent,
  PartialMetaData,
} from "../PanelDef";
import { DropdownItemProps, Icon, Popup, Tab, Table } from "semantic-ui-react";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditPanelFormContainer,
} from "../util";
import { AggregateSelector } from "./AggregateSelector";
import * as uuid from "uuid";
import { fetchSessionTypes } from "../../../../../BytebeamClient";

type AggregateRefsType = {
  [key: number]: React.RefObject<AggregateSelector>;
};

export type EditSessionsMetaState = {
  sessionTypesLoading: boolean;
  sessionTypes: string[];
  rowKeys: number[];
  aggregateRefs: AggregateRefsType;
  panelMeta: SessionsMetaData;
  activeIndex: number;
  error: boolean;
  invalidAggregateNames: string[];
  validAggregates: {
    name: string;
    table: string;
    column: string;
    aggregator: string;
  }[];
  aggregateValidity: boolean;
};

export class EditSessionsMeta extends PanelEditComponent<
  SessionsMetaData,
  EditSessionsMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  static defaultState(): EditSessionsMetaState {
    return {
      rowKeys: [0],
      aggregateRefs: { 0: React.createRef<AggregateSelector>() },
      activeIndex: 0,
      panelMeta: {
        id: uuid.v4(),
        type: "sessions",
        title: "",
        description: "",
        aggregates: [],
        sessionType: "",
        deviceDashboardIds: [],
      },
      error: false,
      invalidAggregateNames: [],
      validAggregates: [],
      aggregateValidity: true,
      sessionTypesLoading: false,
      sessionTypes: [],
    };
  }

  constructor(props: EditComponentProps<SessionsMetaData>) {
    super(props);

    if (props.panelMeta) {
      this.state = this.getStateFromPanelMeta(props);
    } else {
      this.state = EditSessionsMeta.defaultState();
    }
  }

  getStateFromPanelMeta(
    props: EditComponentProps<SessionsMetaData>
  ): EditSessionsMetaState {
    const aggregates = props.panelMeta?.aggregates ?? [];
    let rowKeys = aggregates?.map((_, i) => i);

    if (rowKeys.length === 0) rowKeys = [0];

    const aggregateRefs: AggregateRefsType = {};
    rowKeys.forEach((k) => {
      aggregateRefs[k] = React.createRef<AggregateSelector>();
    });

    return {
      panelMeta: props.panelMeta,
      rowKeys,
      aggregateRefs,
      activeIndex: 0,
      sessionTypesLoading: false,
      sessionTypes: [],
      error: false,
      invalidAggregateNames: [],
      validAggregates: [],
      aggregateValidity: true,
    };
  }

  getAggregates() {
    const rowKeys = [...this.state.rowKeys];
    const aggregateRefs = { ...this.state.aggregateRefs };
    const aggregates = rowKeys
      ?.map((key) => aggregateRefs?.[key])
      ?.filter((ref) => {
        return ref?.current?.isFilled();
      })
      ?.map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }

        return ref.current?.getAggregate();
      });

    return aggregates;
  }

  getPanelMeta(type): PartialMetaData<SessionsMetaData> {
    const validAggregates = this.getAggregates();

    // Create a frequency map to track the count of each aggregate name
    const aggregateFrequencyMap = new Map();

    validAggregates.forEach((aggregate) => {
      const aggregateName = aggregate.name;
      const count = aggregateFrequencyMap.get(aggregateName) || 0;
      aggregateFrequencyMap.set(aggregateName, count + 1);
    });

    const invalidAggregateNames = validAggregates.reduce(
      (acc, aggregate) => {
        const aggregateName = aggregate.name;
        if (aggregateFrequencyMap.get(aggregateName) > 1) {
          // If the name is not unique and not already in the accumulator, add it
          if (!acc.includes(aggregateName)) {
            acc.push(aggregateName);
          }
        } else {
          // If the name is unique, remove it from the accumulator if it exists
          const index = acc.indexOf(aggregateName);
          if (index !== -1) {
            acc.splice(index, 1);
          }
        }
        return acc;
      },
      [...this.state.invalidAggregateNames]
    );

    let aggregateValidity = true;
    const aggregateRefs = this.state.aggregateRefs;
    const firstAggregateState = aggregateRefs[0]?.current?.state;

    // Check if the first aggregate state is empty and no valid aggregates are provided
    if (
      validAggregates.length === 0 &&
      !firstAggregateState?.table &&
      !firstAggregateState?.column &&
      !firstAggregateState?.aggregator
    ) {
      aggregateValidity = true;
    } else {
      // Verify that all aggregate references have valid properties
      aggregateValidity = Object.keys(aggregateRefs).every((ref, index) => {
        const currentState = aggregateRefs[ref]?.current?.state || {};
        const currentValidAggregate = validAggregates[index] || {};

        return (
          currentState.table &&
          currentState.column &&
          currentState.aggregator &&
          currentValidAggregate.name &&
          currentValidAggregate.table &&
          currentValidAggregate.column &&
          currentValidAggregate.aggregator
        );
      });
    }

    this.setState({
      invalidAggregateNames,
      validAggregates,
      aggregateValidity,
    });

    const meta: SessionsMetaData = {
      type: "sessions",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      aggregates: validAggregates,
      sessionType: this.state.panelMeta.sessionType,
      deviceDashboardIds: this.state.panelMeta.deviceDashboardIds,
    };

    let errorMessage = "";
    if (!meta.sessionType) {
      errorMessage = "Please select a session type.";
    } else if (invalidAggregateNames.length > 0 || !aggregateValidity) {
      errorMessage =
        invalidAggregateNames.length > 0
          ? "Please check repeating aggregate names."
          : "Aggregate fields cannot be left empty.";
    }

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
      errorMessage: errorMessage !== "" ? errorMessage : undefined,
    };
  }

  isValidPanelMeta(meta: SessionsMetaData, type?: string): boolean {
    if (type === "submit") {
      const aggregateValidity = this.state.aggregateValidity;
      const noValidAggregates = this.state.invalidAggregateNames.length > 0;
      const sessionType = meta.sessionType;

      // If the session type is not selected, return false
      if (!sessionType) {
        this.setState({ error: true });
        return false;
      }

      // If there are invalid aggregates, return false
      if (!aggregateValidity) {
        this.setState({ error: true });
        return false;
      }

      // If there are no valid aggregates, return false
      if (noValidAggregates) {
        this.setState({ error: true });
        return false;
      }

      // If the checks pass, clear the error state
      this.setState({ error: false });
    }

    return true;
  }

  addRow() {
    this.setState(({ rowKeys, aggregateRefs }) => {
      const newRowKey = Math.max(...rowKeys) + 1;
      const newRefs = Object.assign({}, aggregateRefs, {
        [newRowKey]: React.createRef(),
      });

      return {
        rowKeys: [...rowKeys, newRowKey],
        aggregateRefs: newRefs,
      };
    });
  }

  removeRow(i: number) {
    delete this.state.aggregateRefs[i];

    this.setState({
      rowKeys: this.state.rowKeys.filter((v) => v !== i),
      aggregateRefs: this.state.aggregateRefs,
    });
  }

  setSessionType(value: string) {
    this.setState({
      panelMeta: Object.assign({}, this.state.panelMeta, {
        sessionType: value,
      }),
    });
  }

  setDashboardIdsColumn(value: string[]) {
    this.setState({
      panelMeta: Object.assign({}, this.state.panelMeta, {
        deviceDashboardIds: value,
      }),
    });
  }

  componentDidMount() {
    const initialSessionFetch = async () => {
      try {
        this.setState({ sessionTypesLoading: true });
        const sessionTypes = await fetchSessionTypes();

        // TODO
        // have to check if the response is empty or undefined
        // We can insert that no session found and add validation to disable submit in this case.
        this.setState({
          sessionTypes: sessionTypes?.map((s) => s?.name),
        });
      } catch (e) {
        console.error("Error fetching session types", e);
      } finally {
        this.setState({ sessionTypesLoading: false });
      }
    };

    initialSessionFetch();

    const { panelMeta } = this.props;
    const { aggregates = [] } = panelMeta;

    aggregates?.forEach((aggregate, index) => {
      const ref = this.state.aggregateRefs?.[index];
      const columnType =
        this.props.tables?.[aggregate?.table ?? ""]?.filter(
          (item) => item?.name === aggregate?.column
        )[0]?.type ?? "";

      if (ref?.current) {
        ref.current.setState({
          table: aggregate.table ?? "",
          column: aggregate.column ?? "",
          columnType,
          aggregator: aggregate.aggregator ?? "",
        });
        if (ref.current.nameRef.current) {
          ref.current.nameRef.current.value = aggregate.name ?? "";
        }
      }
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const rowKeys = this.state.rowKeys;
    const aggregates = this.getAggregates();

    const sessionTypeOptions = this.state.sessionTypes.map((s) => {
      return {
        key: s,
        value: s,
        text: s,
      };
    });

    let filteredDeviceDashboards: any = this.props.dashboards?.filter(
      (item) => {
        if (item.type === "fleet") {
          return false;
        } else return true;
      }
    );

    const deviceDashboardIdOptions: DropdownItemProps[] =
      filteredDeviceDashboards.map((item) => {
        const obj = {
          key: String(item.id),
          value: String(item.id),
          text: `${item.id} - ${item.title}`,
        };
        return obj;
      });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditAnimatedMetaDropdown
                selection
                search
                loading={this.state.sessionTypesLoading}
                placeholder="Session Type"
                text={this.state.panelMeta.sessionType}
                options={sessionTypeOptions}
                onChange={(_event, data) =>
                  this.setSessionType(data.value as string)
                }
                defaultValue={this.state.panelMeta.sessionType}
                elementid={"sessionType"}
                error={this.state.error && !this.state.panelMeta.sessionType}
              />
              <ThinDivider />
              <Table>
                <Table.Body>
                  {rowKeys.map((rowKey, i) => {
                    return (
                      <React.Fragment key={rowKey}>
                        {i !== 0 ? <ThinDivider /> : <></>}
                        <AggregateSelector
                          key={rowKey}
                          ref={this.state.aggregateRefs[rowKey]}
                          defaultValue={
                            aggregates.length > i ? aggregates[i] : undefined
                          }
                          showRemoveIcon={rowKeys.length !== 1 || i > 0}
                          showAddIcon={i === rowKeys.length - 1}
                          onRemoveRow={() => this.removeRow(rowKey)}
                          onAddRow={this.addRow.bind(this)}
                          tables={this.props.tables}
                          elementid={i.toString()}
                          invalidAggregateNames={
                            this.state.invalidAggregateNames
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                </Table.Body>
              </Table>
              <ThinDivider />
              <EditMetaRow>
                <div style={{ width: "48%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Device dashboard links"
                    search
                    selection
                    multiple
                    options={deviceDashboardIdOptions}
                    onChange={(_event, data) =>
                      this.setDashboardIdsColumn(data.value as string[])
                    }
                    value={this.state.panelMeta.deviceDashboardIds || []}
                    elementid={"LocateDevicesDashboardID"}
                  />
                </div>{" "}
                <Popup
                  inverted
                  content="Dashboard to view the selected device data"
                  trigger={
                    <Icon style={{ marginLeft: "5px" }} name="info circle" />
                  }
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
