import { LogsMetaData, logLevelOptions } from "./PanelDef";
import React from "react";

import { Radio, Tab } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  LabelHeading,
  DisplayValueDiv,
  EditPanelFormContainer,
  StyledInputDiv,
} from "../util";
import { fetchAllLogTags, TableInfo } from "../../../../../BytebeamClient";

export type EditLogsMetaProps = {
  panelMeta: LogsMetaData;
  tables: TableInfo;
};

export type EditLogsMetaState = {
  table: string;
  logLevel?: string;
  tags?: string[];
  allTags?: string[];
  searchString?: string;
  showSearchBar?: boolean;
  numBins: number;
  error: boolean;
};

export class EditLogsMeta extends PanelEditComponent<
  LogsMetaData,
  EditLogsMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  rowsPerPageRef = React.createRef<HTMLInputElement>();
  numBinsRef = React.createRef<HTMLInputElement>();

  constructor(props: EditLogsMetaProps) {
    super(props);

    this.state = {
      table: props.panelMeta?.table || "",
      logLevel: props.panelMeta?.logLevel,
      tags: props.panelMeta?.tags,
      searchString: props.panelMeta?.searchString,
      showSearchBar: props.panelMeta?.showSearchBar,
      numBins: props.panelMeta.numBins,
      error: false,
    };
  }

  componentDidMount() {
    const getAllLogTags = async () => {
      try {
        const tags = await fetchAllLogTags(this.state.table ?? "logs");
        this.setState({ allTags: tags.data.tags });
      } catch (error) {
        console.error("logTags error: ", error);
        this.setState({ allTags: [] });
      }
    };

    getAllLogTags();
  }

  getPanelMeta(type): PartialMetaData<LogsMetaData> {
    const meta: LogsMetaData = {
      type: "logs",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table,
      logLevel: this.state.logLevel,
      tags: this.state.tags,
      searchString: this.state.searchString,
      showSearchBar: this.state.showSearchBar || false,
      rowsPerPage: parseInt(this.rowsPerPageRef.current?.value || "20"),
      numBins: this.state.numBins || 10,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: LogsMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!meta.table && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  handleSliderChange = (event) => {
    this.setState({ numBins: parseFloat(event.target.value) });
  };

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const tagOptions = (this.state.allTags || []).map((tag) => {
      return {
        key: tag,
        value: tag,
        text: tag,
      };
    });

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.props.panelMeta.table}
                    elementid={"LogsTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="98%">
                  <EditAnimatedMetaInput
                    value={this.state.searchString}
                    label="Search Regex"
                    onChangeEvent={(e) =>
                      this.setState({ searchString: e.target.value as string })
                    }
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    selection
                    fluid
                    clearable
                    options={logLevelOptions}
                    placeholder="Log Level"
                    defaultValue={this.state.logLevel}
                    value={this.state.logLevel}
                    onChange={(_e, d) => {
                      this.setState({ logLevel: d.value as string });
                    }}
                    elementid={"LogsLevel"}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    search
                    multiple
                    selection
                    fluid
                    clearable
                    options={tagOptions}
                    placeholder="Tags"
                    defaultValue={this.state.tags || []}
                    value={this.state.tags || []}
                    onChange={(_e, d) => {
                      this.setState({ tags: d.value as string[] });
                    }}
                    elementid={"LogsTags"}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <EditMetaRow>
                <span>Show search bar</span>
                <Radio
                  style={{ marginLeft: "10px" }}
                  toggle
                  checked={this.state.showSearchBar}
                  onChange={(_e, d) => {
                    this.setState({ showSearchBar: d.checked });
                  }}
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <LabelHeading> Number of Bins </LabelHeading>
                <DisplayValueDiv>{this.state.numBins}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="1"
                    max="250"
                    value={this.state.numBins}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
