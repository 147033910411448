import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Accordion, Button, Grid, Icon, Popup, Table } from "semantic-ui-react";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  AlertGroups,
  AlertRule,
  CompositeCondition,
  Condition,
  SimpleCondition,
} from "../../../../util";
import { Mixpanel } from "../../common/MixPanel";
import CreateAlertRuleModal from "./CreateAlertRuleModal";
import {
  createAlertRule,
  deleteAlertRule,
  startAlertRule,
  stopAlertRule,
  updateAlertRule,
  getTenantFromURL,
} from "../../../../BytebeamClient";
import {
  ButtonIcon,
  DisplayIf,
  capitalizeFirstLetter,
  formatDuration,
} from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import { StyledHeader } from "../../Actions/ActionsV3/SelectableItem";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { AccordionContentContainer } from "../../Settings/tenantsSettings/TenantLevelSettings";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import { useUser } from "../../../../context/User.context";
import { DashboardsInfo } from "../../Dashboards/ViewDashboard";
import { DashboardTitle } from "../../Dashboards/ListDashboards";
import { CardContainer } from "../../../common/ActionsUtils";

export enum AlertModalStepType {
  ChooseAlertType,
  FillAlertTypeDetails,
}

export enum AlertRuleOperationType {
  Create = "create",
  Update = "update",
}

type AlertGroupsAccordionProps = {
  readonly isGroupAccordionActive: Record<string, boolean>;
  readonly setIsGroupAccordionActive: Dispatch<
    SetStateAction<Record<string, boolean>>
  >;
  readonly alertRules: AlertRule[];
  readonly formatCondition: (
    condition: Condition,
    isComposite: boolean
  ) => string;
  readonly setAlertRule: (alertRule: AlertRule) => void;
  readonly setAlertModalStep: (step: AlertModalStepType) => void;
  readonly setOperationType: (operationType: AlertRuleOperationType) => void;
  readonly setOpen: (open: boolean) => void;
  readonly alertsActionLoading: Record<string, boolean>;
  readonly renderToggleButton: (
    alertRule: AlertRule,
    status: string
  ) => React.JSX.Element;
  readonly renderDeleteButton: (alertRule: AlertRule) => React.JSX.Element;
  readonly dashboards: DashboardsInfo[];
};

const AlertGroupsAccordion = (props: AlertGroupsAccordionProps) => {
  const {
    isGroupAccordionActive,
    setIsGroupAccordionActive,
    alertRules,
    formatCondition,
    setAlertRule,
    setAlertModalStep,
    setOperationType,
    setOpen,
    alertsActionLoading,
    renderToggleButton,
    renderDeleteButton,
    dashboards,
  } = props;

  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const tenant = getTenantFromURL();

  // Group the alert rules by group names.
  const groupedAlerts = alertRules.reduce(
    (acc, alertRule) => {
      acc[alertRule.group] = acc[alertRule.group] || [];
      acc[alertRule.group].push(alertRule);
      return acc;
    },
    {} as Record<string, AlertRule[]>
  );

  // Sort the alert groups by name, so that 'default' group is always at the top and the rest are sorted alphabetically.
  const sortedAlertRulesGroups = Object.keys(groupedAlerts).sort((a, b) => {
    if (a === "default") return -1;
    if (b === "default") return 1;
    return a.localeCompare(b);
  });

  const handleAccordionClick = (group: string) => {
    // Toggle the accordion state to close when it is open and undefined (default state)
    setIsGroupAccordionActive((prevState) => ({
      ...prevState,
      // If the group is false, then set it to true, else set it to false
      [group]: prevState[group] === false,
    }));
  };

  const dashboardIdToDataMap = dashboards.reduce(
    (acc, dashboard) => {
      acc[dashboard.id] = dashboard;
      return acc;
    },
    {} as Record<string, DashboardsInfo>
  );

  if ((sortedAlertRulesGroups?.length ?? 0) === 0) {
    return (
      <ErrorMessage
        message={"No Alert Rules found!"}
        marginTop="4em"
        marginBottom="4em"
      />
    );
  }

  return (
    <>
      {sortedAlertRulesGroups.map((alert_group) => (
        <>
          <Accordion
            fluid
            style={{
              padding: "8px 0px",
            }}
          >
            <Accordion.Title
              active={isGroupAccordionActive[alert_group] ?? true}
              onClick={() => handleAccordionClick(alert_group)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledHeader
                  as="h3"
                  style={{
                    margin: "0px",
                    fontSize: "1.1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {alert_group}
                </StyledHeader>
                <Icon
                  style={{ fontSize: "24px", alignItems: "center" }}
                  name={
                    isGroupAccordionActive[alert_group] ?? true
                      ? "angle down"
                      : "angle right"
                  }
                />
              </div>
            </Accordion.Title>
            <Accordion.Content
              active={isGroupAccordionActive[alert_group] ?? true}
            >
              {/* <ThinDivider style={{ margin: "15px 0px 15px 0px" }} /> */}
              <AccordionContentContainer>
                <Table celled fixed id="alertRulesTable">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign="center">
                        Input Stream
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign="center">
                        Condition
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Criticality
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign="center">
                        Thresholds
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1} textAlign="center">
                        Status
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign="center">
                        Last Processed
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign="center">
                        Linked Dashboard
                      </Table.HeaderCell>
                      <DisplayIf cond={permissions.editAlerts}>
                        <Table.HeaderCell textAlign="center">
                          Options
                        </Table.HeaderCell>
                      </DisplayIf>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {groupedAlerts[alert_group].map((alertRule) => {
                      const lpt = alertRule.last_processed_timestamps || {};
                      const dashboardId = alertRule.deepdive_dashboard_id;
                      const dashboard =
                        dashboardIdToDataMap?.[dashboardId as string];

                      return (
                        <Table.Row key={alertRule.id}>
                          <Table.Cell textAlign="center">
                            <TextWithToolTip notBold text={alertRule.name} />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <TextWithToolTip notBold text={alertRule.stream} />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <TextWithToolTip
                              notBold
                              text={formatCondition(alertRule.condition, false)}
                            />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {alertRule.criticality}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            Activation:{" "}
                            {formatDuration(
                              alertRule.activation_threshold_seconds
                            )}
                            <br />
                            Deactivation:{" "}
                            {formatDuration(
                              alertRule.deactivation_threshold_seconds
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {capitalizeFirstLetter(alertRule.status ?? "-")}
                          </Table.Cell>

                          {Object.keys(lpt).length !== 0 ? (
                            <Table.Cell textAlign="center">
                              {Object.keys(lpt).map((key) => {
                                return (
                                  <>
                                    {lpt[key] ? (
                                      <div key={key}>
                                        shard-{key}:{" "}
                                        {moment
                                          .duration(
                                            lpt[key] - new Date().valueOf()
                                          )
                                          .humanize()}{" "}
                                        ago
                                      </div>
                                    ) : (
                                      "--"
                                    )}
                                  </>
                                );
                              })}
                            </Table.Cell>
                          ) : (
                            <Table.Cell textAlign="center">--</Table.Cell>
                          )}

                          <Table.Cell
                            textAlign="center"
                            onClick={() => {
                              if (dashboardId && dashboard?.id)
                                window.open(
                                  `/projects/${tenant}/dashboards/${dashboardId}`,
                                  "_blank"
                                );
                            }}
                          >
                            {dashboardId && dashboard?.id ? (
                              <DashboardTitle>{`${dashboard.id} - ${dashboard.title} (${dashboard.type}) `}</DashboardTitle>
                            ) : (
                              "--"
                            )}
                          </Table.Cell>

                          <DisplayIf cond={permissions.editAlerts}>
                            <Table.Cell textAlign="center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "12px",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <ButtonIcon
                                  link
                                  name="edit"
                                  title="Edit Alert Rule"
                                  onClick={() => {
                                    setAlertRule(alertRule);
                                    setAlertModalStep(
                                      AlertModalStepType.FillAlertTypeDetails
                                    );
                                    setOperationType(
                                      AlertRuleOperationType.Update
                                    );
                                    setOpen(true);
                                  }}
                                />
                                {alertsActionLoading[alertRule.name] ? (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      marginRight: "6px",
                                    }}
                                  >
                                    <LoadingAnimation
                                      loaderSize="14px"
                                      loaderBorderSize="3px"
                                    />
                                  </span>
                                ) : (
                                  renderToggleButton(
                                    alertRule,
                                    alertRule.status ?? "stopped"
                                  )
                                )}
                                {renderDeleteButton(alertRule)}
                              </div>
                            </Table.Cell>
                          </DisplayIf>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </AccordionContentContainer>
            </Accordion.Content>
          </Accordion>
          <ThinDivider style={{ margin: "8px 0px" }} />
        </>
      ))}
    </>
  );
};

type AlertRulesTableProps = {
  readonly alertRules: AlertRule[];
  readonly alertGroups: AlertGroups;
  readonly setLoading: (loading: boolean) => void;
  readonly onChange: (showLoading: boolean) => void;
  readonly newAlertRuleName: string;
  readonly setNewAlertRuleName: (name: string) => void;
  readonly dashboards: DashboardsInfo[];
};

export default function AlertRules(props: AlertRulesTableProps) {
  const {
    alertRules,
    alertGroups,
    setLoading,
    onChange,
    newAlertRuleName,
    setNewAlertRuleName,
    dashboards,
  } = props;

  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const [open, setOpen] = useState(false);
  const [alertRule, setAlertRule] = useState<AlertRule>({} as AlertRule);
  const [alertModalStep, setAlertModalStep] = useState<number>(
    AlertModalStepType.ChooseAlertType
  );
  const [operationType, setOperationType] = useState<string>(
    AlertRuleOperationType.Create
  );
  const [alertsActionLoading, setAlertsActionLoading] = useState<{
    [key: string]: boolean;
  }>({});
  // Alert group accordion active state
  const [isGroupAccordionActive, setIsGroupAccordionActive] = useState<
    Record<string, boolean>
  >({});

  const formatCondition = (
    condition: Condition,
    wrapInBrackets: boolean = false
  ) => {
    const compositeOperators = ["and", "or"];
    const noDataOperator = "no_data";

    if (condition.operator === noDataOperator) {
      return "No Data";
    } else if (compositeOperators.includes(condition.operator)) {
      const conditions = (condition as CompositeCondition).conditions;
      const s = conditions
        .map((c) => formatCondition(c, true))
        .join(` ${condition.operator} `);
      return wrapInBrackets ? `(${s})` : s;
    } else {
      const c = condition as SimpleCondition;
      const s = `${c.field} ${c.operator} ${c.value}`;

      return wrapInBrackets ? `(${s})` : s;
    }
  };

  const _startAlertRule = async (alertRule: AlertRule) => {
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;
    try {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: true,
      }));
      await startAlertRule(alertRuleId);
      setNewAlertRuleName("");
      // Sleep for a 5 seconds to allow the alert rule to start
      await new Promise((resolve) => setTimeout(resolve, 5000));

      beamtoast.success(`Started alert rule "${alertRuleName}"`);
      onChange(false);
    } catch (e) {
      beamtoast.error(`Failed to start alert rule "${alertRuleName}"`);
      console.log(e);
    } finally {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: false,
      }));
    }
  };

  const _stopAlertRule = async (alertRule: AlertRule) => {
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;
    try {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: true,
      }));
      await stopAlertRule(alertRuleId);
      // Sleep for a 5 seconds to allow the alert rule to stop
      await new Promise((resolve) => setTimeout(resolve, 5000));
      beamtoast.success(`Stopped alert rule "${alertRuleName}"`);
      onChange(false);
    } catch (e) {
      beamtoast.error(`Failed to stop alert rule "${alertRuleName}"`);
      console.log(e);
    } finally {
      setAlertsActionLoading((prevLoading) => ({
        ...prevLoading,
        [alertRule.name]: false,
      }));
    }
  };

  const renderToggleButton = (alertRule: AlertRule, status: string) => {
    if (status === "stopped") {
      return (
        <Popup
          content="Click here to start Alert"
          inverted
          position="top center"
          open={
            alertRule.name === newAlertRuleName &&
            isGroupAccordionActive?.[`${alertRule.group}`] !== false
          }
          trigger={
            <ButtonIcon
              link
              name="play"
              title="Start Alert Rule"
              onClick={() => _startAlertRule(alertRule)}
            />
          }
        />
      );
    } else {
      return (
        <ButtonIcon
          link
          name="pause"
          title="Stop Alert Rule"
          onClick={() => _stopAlertRule(alertRule)}
        />
      );
    }
  };

  // Passed alertName also, and show that in delete confirmation.
  const renderDeleteButton = (alertRule: AlertRule) => {
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;
    const isNotificationRuleExist =
      (alertRule?.notification_rules ?? []).length > 0;
    const isAlertRuleRunning = alertRule.status === "running";

    if (isNotificationRuleExist || isAlertRuleRunning) {
      return (
        <Popup
          trigger={
            <ButtonIcon
              link
              name="trash"
              disabled={isAlertRuleRunning || isNotificationRuleExist}
            />
          }
          content={
            isAlertRuleRunning
              ? "Alert Rule is in running state"
              : "Alert Rule is assigned to some Notification Rule"
          }
          inverted
          position="top center"
        />
      );
    } else {
      return (
        <ConfirmationModal
          trigger={<ButtonIcon link name="trash" title="Delete Alert Rule" />}
          prefixContent="Delete Alert Rule"
          expectedText={alertRuleName}
          message={
            <ConfirmationModalMessage
              name={alertRuleName}
              type={"Alert Rule"}
              specialMessage="Note that this will also delete any alerts created by this rule"
            />
          }
          onConfirm={async () => {
            setLoading(true);

            try {
              await deleteAlertRule(alertRuleId);
              beamtoast.success(`Deleted alert rule "${alertRuleName}"`);
            } catch (e) {
              beamtoast.error(`Failed to delete alert rule "${alertRuleName}"`);
              console.log(e);
            } finally {
              onChange(true);
            }
          }}
        />
      );
    }
  };

  const handleCreateAlertType = async (alertRule: AlertRule) => {
    alertRule.name = alertRule.name.trim();
    const alertRuleName = alertRule.name;

    setNewAlertRuleName("");
    try {
      // Remove id from alertRule
      const rule: any = { ...alertRule };
      delete rule.id;

      await createAlertRule(rule);

      setNewAlertRuleName(rule.name);

      beamtoast.success(`Created new alert rule ${alertRuleName}`);
      Mixpanel.track("Created Alert Type", {});
      onChange(true);
    } catch (e) {
      beamtoast.error(`Failed to create alert rule ${alertRuleName}`);
      Mixpanel.track("Failure", {
        type: "Alert Type creation",
        error: JSON.stringify(e),
      });
      console.log(e);
    }
  };

  const handleEditAlertType = async (alertRule: AlertRule) => {
    alertRule.name = alertRule.name.trim();
    const alertRuleId = alertRule.id;
    const alertRuleName = alertRule.name;

    try {
      setLoading(true);
      await updateAlertRule(alertRuleId, alertRule);

      beamtoast.success(`Updated alert rule "${alertRuleName}" Successfully`);
      Mixpanel.track("Updated Alert Rule", {
        id: alertRuleId,
        name: alertRule.name,
      });

      // Wait for 5s for the service to come back up
      await new Promise((resolve) => setTimeout(resolve, 5000));
    } catch (e) {
      beamtoast.error(`Failed to edit alert rule "${alertRuleName}"`);

      Mixpanel.track("Failure", {
        type: "Alert Rule edit",
        error: JSON.stringify(e),
      });
      console.log(e);
    } finally {
      onChange(true);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setAlertModalStep(AlertModalStepType.ChooseAlertType);
    setOperationType(AlertRuleOperationType.Create);
    setAlertRule({} as AlertRule);
  };

  useEffect(() => {
    setTimeout(() => {
      setNewAlertRuleName("");
    }, 60000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardContainer>
      <Grid>
        <CreateAlertRuleModal
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleModalClose}
          title={
            operationType === AlertRuleOperationType.Create
              ? "New Alert Rule"
              : "Edit Alert Rule"
          }
          alertRule={alertRule}
          operationType={operationType}
          alertModalStep={alertModalStep}
          onSubmit={
            operationType === AlertRuleOperationType.Create
              ? handleCreateAlertType
              : handleEditAlertType
          }
          alertRules={alertRules}
          alertGroups={alertGroups}
          dashboards={dashboards}
        />
        <Grid.Row>
          {alertRules.length > 0 && (
            <Grid.Column
              width={8}
              style={{ alignItems: "center", display: "flex", gap: "8px" }}
            >
              <Button
                title="Expand All Accordions"
                secondary
                id="ExpandAllAccordionButton"
                disabled={Object.values(isGroupAccordionActive).every(
                  (value) => value === true
                )}
                onClick={() => {
                  let temp = {};
                  for (let key of alertGroups) {
                    temp[key] = true;
                  }
                  setIsGroupAccordionActive(temp);
                }}
              >
                <Icon name="plus square outline" />
                <strong>Expand all</strong>
              </Button>
              <Button
                title="Collapse All Accordions"
                secondary
                id="CollapseAllAccordionButton"
                disabled={
                  Object.values(isGroupAccordionActive).length > 0 &&
                  Object.values(isGroupAccordionActive).every(
                    (value) => value === false
                  )
                }
                onClick={() => {
                  let temp = {};
                  for (let key of alertGroups) {
                    temp[key] = false;
                  }
                  setIsGroupAccordionActive(temp);
                }}
              >
                <Icon name="minus square outline" />
                <strong>Collapse all</strong>
              </Button>
            </Grid.Column>
          )}
          <Grid.Column width={alertGroups.length > 1 ? 8 : 16}>
            <DisplayIf cond={permissions.editAlerts ?? false}>
              <Button
                id="addAlertRuleButton"
                primary
                floated="right"
                icon
                labelPosition="left"
                onClick={() => {
                  setAlertModalStep(AlertModalStepType.ChooseAlertType);
                  setOperationType(AlertRuleOperationType.Create);
                  setOpen(true);
                }}
              >
                <Icon name="plus" />
                Alert Rule
              </Button>
            </DisplayIf>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <AlertGroupsAccordion
              dashboards={dashboards}
              isGroupAccordionActive={isGroupAccordionActive}
              setIsGroupAccordionActive={setIsGroupAccordionActive}
              alertRules={alertRules}
              formatCondition={formatCondition}
              setAlertRule={setAlertRule}
              setAlertModalStep={setAlertModalStep}
              setOperationType={setOperationType}
              setOpen={setOpen}
              alertsActionLoading={alertsActionLoading}
              renderToggleButton={renderToggleButton}
              renderDeleteButton={renderDeleteButton}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CardContainer>
  );
}
