import React, { useState, useMemo, useEffect } from "react";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import Layout from "../../common/Layout";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  DeviceComponent,
  FirmwareType,
  IDeviceComponent,
  IDeviceComponentResult,
  createDeviceComponent,
  deleteDeviceComponent,
  fetchAllComponentsFirmwares,
  fetchAllDeviceComponents,
  updateDeviceComponent,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";
import { TableHeaderCellWithSorting } from "../../../common/TableHeaderCellWithSorting";
import CreateOrEditDeviceComponentModal from "./CreateOrEditDeviceComponentModal";
import { ButtonIcon } from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import { beamtoast } from "../../../common/CustomToast";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import { AxiosError } from "axios";

interface CreateComponentButtonProps {
  readonly onUpdate: () => void;
  readonly components: IDeviceComponent[];
}

function CreateComponentButton(props: CreateComponentButtonProps) {
  async function handleSubmit(component: DeviceComponent): Promise<void> {
    try {
      await createDeviceComponent(component);
      Mixpanel.track("Created Device Component", {
        componentName: component.name,
      });
      beamtoast.success(
        `Device Component ${component.name} created successfully`
      );
      props.onUpdate();
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 409) {
        beamtoast.error("Device Component with this name already existed");
      } else {
        beamtoast.error("Failed to create Device Component");
      }

      Mixpanel.track("Failure during device component creation", {
        type: "Device Component creation",
        error: axiosError.message,
      });
      console.error(axiosError);
    }
  }

  return (
    <CreateOrEditDeviceComponentModal
      operationType="Create"
      onSubmit={async (component) => await handleSubmit(component)}
      component={{ id: 0, name: "", is_deleted: false }}
      allComponentsName={new Set(props.components?.map((c) => c.name))}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Device Component
        </Button>
      }
    />
  );
}

interface EditComponentButtonProps {
  readonly component: IDeviceComponent;
  readonly components: IDeviceComponent[];
  readonly onUpdate: () => void;
}

function EditComponentButton(props: EditComponentButtonProps) {
  async function handleSubmit(component: DeviceComponent) {
    try {
      await updateDeviceComponent(props.component.id, component);
      Mixpanel.track("Updated Device Component", {
        componentName: component.name,
      });
      beamtoast.success(
        `Device Component "${component.name}" updated successfully`
      );
      props.onUpdate();
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 409) {
        beamtoast.error(
          "Device component with this name already existed, please choose a different component name"
        );
      } else {
        beamtoast.error("Failed to create Device Component");
      }
      Mixpanel.track("Failure during editing device component", {
        type: "device component editing",
        error: JSON.stringify(axiosError),
      });
      console.log(axiosError);
    }
  }

  return (
    <CreateOrEditDeviceComponentModal
      operationType="Edit"
      onSubmit={async (component) => await handleSubmit(component)}
      component={props.component}
      allComponentsName={new Set(props.components.map((c) => c.name))}
      trigger={<ButtonIcon link name="pencil" title="Edit Device Component" />}
    />
  );
}

export default function DeviceComponents() {
  const [loading, setLoading] = useState<boolean>(true);
  const [components, setComponents] = useState<IDeviceComponent[]>([]);
  const [filteredComponents, setFilteredComponents] = useState<
    IDeviceComponent[]
  >([]);
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareType[]>([]);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | undefined;
  }>({ key: "id", direction: "ascending" });

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res: IDeviceComponentResult = await fetchAllDeviceComponents();
      setComponents(res.results);
      setFilteredComponents(res.results);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (component) => {
    try {
      await deleteDeviceComponent(component.id);
      Mixpanel.track("Deleted Device Component", {
        componentId: component.id,
        componentName: component.name,
      });
      beamtoast.success(
        `Device Component "${component.name}" deleted successfully`
      );
      handleUpdate();
    } catch (e) {
      Mixpanel.track("Failure during deleting device component", {
        type: "device component deletion",
        error: JSON.stringify(e),
      });
      beamtoast.error(`Failed to delete Device Component ${component.name}`);
      console.log(e);
    }
  };

  const onSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedComponents = useMemo(() => {
    if (sortConfig.key) {
      filteredComponents?.sort((a, b) => {
        let valueA = a?.[sortConfig.key];
        let valueB = b?.[sortConfig.key];

        if (valueA < valueB) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredComponents;
  }, [filteredComponents, sortConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.title = "Device Components | Bytebeam";

    async function fetchInitialData() {
      setLoading(true);
      try {
        const res: IDeviceComponentResult = await fetchAllDeviceComponents();
        setComponents(res.results);
        setFilteredComponents(res.results);
        const firmwares = await fetchAllComponentsFirmwares();
        const filteredResponse = firmwares.filter(
          (version) => !version.is_deactivated
        );
        setFirmwareVersions(filteredResponse);
      } catch (error) {
        setErrorOccurred(true);
      } finally {
        setLoading(false);
      }
    }

    fetchInitialData();
  }, []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading device components..."
      />
    );
  }

  return (
    <Layout
      buttons={
        <CreateComponentButton
          onUpdate={handleUpdate}
          components={components}
        />
      }
    >
      <Table celled fixed>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellWithSorting
              label="Id"
              columnKey="id"
              onSort={onSort}
              sortConfig={sortConfig}
            />
            <TableHeaderCellWithSorting
              label="Name"
              columnKey="name"
              onSort={onSort}
              sortConfig={sortConfig}
            />
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedComponents?.length !== 0 ? (
            sortedComponents?.map((component) => {
              const isDeleteDisabled = firmwareVersions.some(
                (version) => version.device_component_name === component.name
              );

              return (
                <Table.Row key={component.name}>
                  <Table.Cell>{component.id}</Table.Cell>
                  <Table.Cell>
                    <TextWithToolTip text={component.name} />
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "18px",
                        flexWrap: "nowrap",
                      }}
                    >
                      <EditComponentButton
                        component={component}
                        onUpdate={handleUpdate}
                        components={components}
                      />
                      {isDeleteDisabled ? (
                        <Popup
                          trigger={
                            <ButtonIcon
                              link
                              name="trash"
                              disabled={isDeleteDisabled}
                            />
                          }
                          content={"This component has firmware versions"}
                          inverted
                          position="top center"
                        />
                      ) : (
                        <ConfirmationModal
                          prefixContent="Delete Device Component"
                          expectedText={component.name}
                          onConfirm={() => handleDelete(component)}
                          trigger={
                            <ButtonIcon
                              link
                              name="trash"
                              title="Delete Device Component"
                              disabled={isDeleteDisabled}
                            />
                          }
                          message={
                            <ConfirmationModalMessage
                              name={component.name}
                              type={"Device Component"}
                              specialMessage=""
                            />
                          }
                        />
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                <ErrorMessage
                  marginTop="30px"
                  message={"No Device Components found!"}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}
