import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import { Timeseries } from "./PanelDef";
import {
  DateTimeStreamsFieldsTypes,
  EditAnimatedMetaDropdown,
  StyledInputDiv,
  conditionalAggregators,
} from "../util";
import { StreamFieldDetails, TableInfo } from "../../../../../BytebeamClient";
import Toggle from "../../../../common/Toggle";
import { Condition } from "../../../../../util";
import { objectToQuery, queryToObject } from "../../../common/QuerySelector";
import { AdvancedSettingsModal } from "./AdvancedSettingsModal";

export const parseSQLwhereToQueryObject = (sql: Condition, operator) => {
  if (operator) {
    let parsedQuery = objectToQuery(sql);
    parsedQuery[0] = operator;
    return parsedQuery;
  } else {
    return objectToQuery(sql);
  }
};

export const parseQueryObjectToSQL = (query) => {
  if (query && query.length > 1) {
    const compositeOperator = query[0] || "and";
    const queryObj = queryToObject(query);
    const conditions = queryObj.map((condition) => {
      return {
        field: condition.column,
        operator: condition.operator,
        value: condition.value,
      };
    });
    return { conditions, operator: compositeOperator };
  } else {
    return {
      conditions: [{ field: "", operator: "=", value: "" }],
      operator: "and",
    };
  }
};

type TimeseriesSelectorProps = {
  showRemoveIcon: boolean;
  showAddIcon: boolean;
  onAddRow: () => any;
  onRemoveRow: () => any;
  defaultValue?: Timeseries;
  tables: TableInfo;
  elementid: string;
  showAlternateAxisToggle?: boolean;
  dashboardType: string;
};

type TimeseriesSelectorState = {
  table: string;
  column: string;
  columnType: string;
  aggregator: string;
  alternateAxis: boolean;
  error: boolean;
  isModalOpen: boolean;
  query?: Condition | any;
  groupBys?: string[];
};

export class TimeseriesSelector extends React.Component<
  TimeseriesSelectorProps,
  TimeseriesSelectorState
> {
  constructor(props) {
    super(props);

    if (props.defaultValue) {
      this.state = props.defaultValue;
    } else {
      this.state = {
        table: "",
        column: "",
        columnType: "",
        aggregator: "",
        alternateAxis: false,
        error: false,
        isModalOpen: false,
        query: undefined,
      };
    }
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      column: "",
      columnType: "",
      aggregator: "",
    });
  }

  setColumn(_event, data) {
    const columnType = data.options.filter(
      (item) => item.value === data.value
    )[0].type;
    this.setState({
      column: data.value,
      columnType: columnType,
      aggregator: "",
    });
  }

  setAggregator(_event, data) {
    this.setState({
      aggregator: data.value,
    });
  }

  setAlternateAxis() {
    this.setState({
      alternateAxis: !this.state.alternateAxis,
    });
  }

  toggleAdvancedModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  getTimeseries() {
    const { table, column, aggregator, query, groupBys, alternateAxis } =
      this.state;
    return { table, column, aggregator, query, groupBys, alternateAxis };
  }

  getTimeseriesWithAltAxis() {
    const { table, column, aggregator, alternateAxis } = this.state;
    return { table, column, aggregator, alternateAxis };
  }

  isFilled(type: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      !(!!this.state.table && !!this.state.column && !!this.state.aggregator) &&
      type === "submit"
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!this.state.table && !!this.state.column && !!this.state.aggregator;
  }

  setFilterCondition = (conditions, compositeOperator) => {
    if (conditions && conditions.length > 0 && conditions[0].column !== "") {
      //Parse into query object
      const query = parseSQLwhereToQueryObject(conditions, compositeOperator);
      this.setState({ query: query });
    } else {
      this.setState({ query: undefined });
    }
  };

  setGroupByField = (groupBy) => {
    if (groupBy && groupBy.length > 0) {
      this.setState({ groupBys: groupBy });
    } else {
      this.setState({ groupBys: undefined });
    }
  };

  render() {
    const defaultGroupBy = this.props.defaultValue
      ? this.props.defaultValue.groupBys
      : undefined;
    let defaultFilter = this.props.defaultValue
      ? this.props.defaultValue.query
      : undefined;
    defaultFilter = parseQueryObjectToSQL(defaultFilter);

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
      required?: boolean;
      seq_id: number;
    }> = [];

    if (this.state.table) {
      try {
        columnTypeOptions = this.props.tables[this.state.table].map(
          (f: {
            name: string;
            type: string;
            required: boolean;
            seq_id: number;
          }) => {
            return {
              key: f.name,
              text: f.name,
              value: f.name,
              type: f.type,
              required: f.required,
              seq_id: f.seq_id,
            };
          }
        );
      } catch (e) {
        console.error(`${this.state.table} stream is not found in tables`);
        console.error(e);
      }
    }

    const aggregatorOptions = [
      ...conditionalAggregators(this.state.columnType).filter((agg) =>
        DateTimeStreamsFieldsTypes.includes(this.state.columnType)
          ? agg !== "sum" && agg !== "avg"
          : agg
      ),
      ...(!["Nullable(String)", "String"].includes(this.state.columnType)
        ? ["largestTriangleThreeBuckets"]
        : []),
    ].map((a) => {
      return {
        key: a,
        text: a,
        value: a,
      };
    });

    // for use in where filter
    let fields: { [key: string]: StreamFieldDetails } =
      columnTypeOptions.reduce(
        (obj, item) => ({
          ...obj,
          [item.key]: {
            type: item.type,
            required: item.required || false, // Assuming false if 'required' is not provided.
            seq_id: item.seq_id,
          },
        }),
        {}
      );

    return (
      <>
        <Table.Row
          style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
        >
          <Table.Cell style={{ width: "50%", borderStyle: "none" }}>
            <EditAnimatedMetaDropdown
              placeholder="Select Stream"
              text={this.state.table || "Select Stream"}
              search
              selection
              options={tableOptions}
              onChange={this.setTable.bind(this)}
              defaultValue={this.state.table || ""}
              value={this.state.table || ""}
              error={this.state.error && !this.state.table}
              elementid={`table${this.props.elementid}`}
            />
          </Table.Cell>

          <Table.Cell style={{ width: "50%", borderStyle: "none" }}>
            <EditAnimatedMetaDropdown
              placeholder="Select Field"
              text={this.state.column || "Select Field"}
              search
              selection
              disabled={this.state.table === ""}
              options={columnTypeOptions}
              onChange={this.setColumn.bind(this)}
              defaultValue={this.state.column || ""}
              value={this.state.column || ""}
              error={this.state.error && !this.state.column}
              elementid={`column${this.props.elementid}`}
            />
          </Table.Cell>

          <Table.Cell style={{ width: "50%", borderStyle: "none" }}>
            <EditAnimatedMetaDropdown
              placeholder="Aggregator"
              text={this.state.aggregator || "Select Aggregator"}
              search
              selection
              disabled={this.state.column === ""}
              options={aggregatorOptions}
              onChange={this.setAggregator.bind(this)}
              defaultValue={this.state.aggregator || ""}
              value={this.state.aggregator || ""}
              error={this.state.error && !this.state.aggregator}
              elementid={`aggregate${this.props.elementid}`}
            />
          </Table.Cell>

          <StyledInputDiv
            width="50%"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Table.Cell style={{ borderStyle: "none" }}>
              <div>
                {this.props.showRemoveIcon ? (
                  <Button secondary onClick={this.props.onRemoveRow} icon>
                    <Icon name="minus" />
                  </Button>
                ) : (
                  <></>
                )}
                {this.props.showAddIcon ? (
                  <Button primary onClick={this.props.onAddRow} icon>
                    <Icon name="add" />
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </Table.Cell>

            <Table.Cell
              style={{ textAlign: "right", flex: 1, borderBottom: "none" }}
            >
              {/* Advanced Button */}
              <Button
                secondary
                onClick={this.toggleAdvancedModal}
                icon
                disabled={!this.state.table}
              >
                <Icon name="setting" />
                Advanced
              </Button>

              {/* Modal for Advanced Settings */}
              <AdvancedSettingsModal
                fields={fields}
                groupByFields={columnTypeOptions.map((f) => ({
                  key: f.key,
                  text: f.text,
                  value: f.value,
                  type: f.type,
                }))}
                setFilterCondition={this.setFilterCondition}
                setGroupByField={this.setGroupByField}
                filterCondition={defaultFilter}
                groupByCondition={defaultGroupBy}
                dashboardType={
                  this.props.dashboardType ? this.props.dashboardType : ""
                }
                isOpen={this.state.isModalOpen}
                close={() => this.toggleAdvancedModal()}
              />
            </Table.Cell>
          </StyledInputDiv>
        </Table.Row>
        <Table.Row>
          {this.props.showAlternateAxisToggle ? (
            <Table.Cell style={{ borderStyle: "none" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "0 15px 15px 15px",
                }}
              >
                <div>
                  <Toggle
                    checked={this.state.alternateAxis}
                    onChange={() => this.setAlternateAxis()}
                    style={{ top: "2px", marginRight: "10px" }}
                  />
                </div>
                <div>Use alternate axis on the right</div>
              </div>
            </Table.Cell>
          ) : (
            <></>
          )}
        </Table.Row>
      </>
    );
  }
}
