import React, { useEffect, useState } from "react";
import {
  EmailNotificationChannel,
  NotificationRule,
} from "../CreateNotificationRule";
import {
  StyledInput,
  StyledLabel,
} from "../../AlertRules/CreateAlertRuleModal";
import { ErrorMessage } from "../../../Settings/roles/CreateOrEditRoleModal";
import { validateRestrictedEmail } from "../../../../../util";

type CreateEmailNotificationChannelProps = {
  readonly channel: EmailNotificationChannel;
  readonly setChannel: (channel: EmailNotificationChannel) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly notificationRule: NotificationRule;
};

export default function CreateEmailNotificationChannel(
  props: CreateEmailNotificationChannelProps
) {
  const { setIsValid, channel, setChannel, notificationRule } = props;
  const [validationError, setValidationError] = useState<string | null>(null);
  const [blurred, setBlurred] = useState(false);

  useEffect(() => {
    const channelEmails = channel.emails.filter((e) => e.length > 0);
    const isValid =
      channelEmails.length > 0 &&
      notificationRule.alertRuleId !== "" &&
      !validationError;

    setIsValid(isValid);
  }, [channel, setIsValid, notificationRule.alertRuleId, validationError]);

  const setEmails = (emailString: string) => {
    const emails = emailString.split(",").map((e) => e.trim());
    const areEmailsValid = emails.every(
      (e) => e === "" || validateRestrictedEmail(e)
    );

    if (emailString.length > 0 && !areEmailsValid) {
      // Set local validation error state if blurred and invalid
      setValidationError("Invalid email format");
    } else {
      // Reset the error if emails are valid or not blurred
      setBlurred(false);
      setValidationError(null);
    }

    setChannel({
      ...channel,
      emails,
    });
  };

  const handleBlur = () => {
    // Set blurred to true when input field is blurred
    setBlurred(true);
  };

  const handleFocus = () => {
    if (!validationError) {
      setBlurred(false);
    }
  };

  return (
    <div style={{ marginBottom: "14px" }}>
      <StyledInput style={{ marginBottom: "0px" }} labelPosition="left">
        <StyledLabel>Emails</StyledLabel>
        <input
          value={channel.emails}
          type="email"
          multiple
          onChange={(e) => setEmails(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="Comma-separated list of emails"
        />
      </StyledInput>
      {validationError && blurred && (
        <ErrorMessage
          id="validation_message"
          style={{ fontWeight: 700, top: "0px" }}
        >
          {validationError}
        </ErrorMessage>
      )}
    </div>
  );
}
