import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  SemanticICONS,
  Table,
} from "semantic-ui-react";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  DBCOffsetResponse,
  DBCResponse,
  ParsedDBCResponse,
} from "../../../../util";
import {
  deleteDBC,
  fetchAllDBCs,
  fetchDBCFile,
  fetchParsedDBC,
  startDBCParser,
  stopDBCParser,
} from "../../../../BytebeamClient";
import {
  ButtonGroupedIcon,
  ButtonIcon,
  capitalizeFirstLetter,
} from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import CreateOrEditDBCModal from "./CreateOrEditDBCModal";
import { useLocation } from "react-router-dom";
import ViewDBCTableModal from "./ViewDBCTableModal";
import styled from "styled-components";
import BrowserUpdatedIcon from "../../../../assets/svg/BrowserUpdatedIcon";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import UpdateDBCParsingModal from "./UpdateDBCParsingModal";
import PopupWithLowZIndex from "../../common/PopupWithLowZIndex";

const OffsetDiv = styled.div`
  white-space: nowrap;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export enum DBCOperationType {
  Create = "create",
  Edit = "edit",
}

export default function DBC() {
  const location = useLocation();
  const abortControllerRef = useRef(new AbortController());
  const isMounted = useRef<boolean>(true);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const [DBCData, setDBCData] = useState<DBCResponse[]>([]);
  const [parsedDBCData, setParsedDBCData] = useState<ParsedDBCResponse>();
  const [selectedDBC, setSelectedDBC] = useState<DBCResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const [newDBCOutputStream, setNewDBCOutputStream] = useState<string>("");

  const [openCreateOrEditDBCModal, setOpenCreateOrEditDBCModal] =
    useState(false);
  const [operationType, setOperationType] = useState<string>(
    DBCOperationType.Create
  );

  const [viewDBCTableModal, setViewDBCTableModal] = useState<boolean>(false);
  const [isUpdateDBCParsingModalOpen, setIsUpdateDBCParsingModalOpen] =
    useState<boolean>(false);

  const handleUpdate = useCallback(async (reset: boolean) => {
    if (!isMounted.current) return;

    if (reset) {
      // Check if there's an ongoing fetch request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for the new API request
      abortControllerRef.current = new AbortController();
    }

    try {
      const res = await fetchAllDBCs(abortControllerRef.current.signal);
      setDBCData(res.results);
    } catch (error) {
      if (error instanceof DOMException && error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error occurred in apiCall:", error);
        setErrorOccurred(true);
      }
    } finally {
      setLoading(false);
      if (isMounted.current) {
        timeoutId.current = setTimeout(() => {
          handleUpdate(false);
        }, 2 * 1000);
      }
    }
  }, []);

  const handleModalClose = () => {
    setOpenCreateOrEditDBCModal(false);
    setSelectedDBC(undefined);
    setOperationType(DBCOperationType.Create);
  };

  const startDBC = async (dbc: DBCResponse) => {
    const DbcID = dbc.id;
    const DBCName = dbc.name;
    try {
      setLoading(true);
      await startDBCParser(DbcID);
      beamtoast.success(`Started DBC Parser "${DBCName}"`);
      setNewDBCOutputStream("");
      handleUpdate(true);
    } catch (e) {
      beamtoast.error(`Failed to start DBC Parser "${DBCName}"`);
      console.log(e);
    }
  };

  const stopDBC = async (dbc: DBCResponse) => {
    const DbcID = dbc.id;
    const DBCName = dbc.name;
    try {
      setLoading(true);
      await stopDBCParser(DbcID);
      beamtoast.success(`Stopped DBC Parser "${DBCName}"`);
      setNewDBCOutputStream("");
      handleUpdate(true);
    } catch (e) {
      beamtoast.error(`Failed to stop DBC Parser "${DBCName}"`);
      console.log(e);
    }
  };

  const renderToggleButton = (
    dbc: DBCResponse,
    status: DBCResponse["status"]
  ) => {
    const getPopupContent = () => {
      switch (status) {
        case "stopped":
          return {
            content: "Click here to start DBC Parser",
            // Hide the button when any DBC modal is open, show the popup when newDBC name is same as the current dbc name.
            open:
              dbc.output_table === newDBCOutputStream
                ? dbc.output_table === newDBCOutputStream &&
                  isUpdateDBCParsingModalOpen === false &&
                  openCreateOrEditDBCModal === false &&
                  viewDBCTableModal === false
                : undefined,
            name: "play" as SemanticICONS,
            onClick: () => startDBC(dbc),
            disabled: false,
          };
        case "updating":
          return {
            content: "DBC Parser is updating",
            open: undefined,
            name: "pause" as SemanticICONS,
            onClick: () => stopDBC(dbc),
            disabled: true,
          };
        default:
          return {
            content: "Stop DBC Parser",
            open: undefined,
            name: "pause" as SemanticICONS,
            onClick: () => stopDBC(dbc),
            disabled: false,
          };
      }
    };

    const { content, name, open, onClick, disabled } = getPopupContent();

    return (
      <PopupWithLowZIndex
        content={content}
        open={open}
        trigger={
          <ButtonIcon
            style={{ height: "auto" }}
            link
            name={name}
            onClick={onClick}
            disabled={disabled}
          />
        }
      />
    );
  };

  const renderDeleteButton = (dbc: DBCResponse) => {
    const DbcID = dbc.id;
    const DBCName = dbc.name;
    return (
      <PopupWithLowZIndex
        content={
          dbc.status !== "stopped"
            ? "Cannot delete in running state!"
            : "Delete DBC Parser"
        }
        trigger={
          <div>
            {dbc.status !== "stopped" ? (
              <ButtonIcon link name="trash" disabled />
            ) : (
              <ConfirmationModal
                trigger={
                  <ButtonIcon
                    link
                    name="trash"
                    onClick={() => setNewDBCOutputStream("")}
                  />
                }
                prefixContent="Delete DBC Parser"
                expectedText={DBCName}
                message={
                  <ConfirmationModalMessage
                    name={DBCName}
                    type={"DBC"}
                    specialMessage=""
                  />
                }
                onConfirm={async () => {
                  if (dbc.status === "started")
                    beamtoast.error("Cannot delete in running state!");
                  else {
                    setLoading(true);
                    try {
                      await deleteDBC(DbcID);
                      beamtoast.success(`Deleted DBC Parser"${DBCName}"`);
                    } catch (e) {
                      beamtoast.error(
                        `Failed to delete DBC Parser "${DBCName}"`
                      );
                      console.log(e);
                    } finally {
                      handleUpdate(true);
                    }
                  }
                }}
              />
            )}
          </div>
        }
      />
    );
  };

  const downloadFile = (
    content: string,
    fileName: string,
    contentType: string
  ) => {
    const blob = new Blob([content], { type: `${contentType};charset=utf-8` });
    const url = URL.createObjectURL(blob);
    const dbcFile = document.createElement("a");
    dbcFile.href = url;
    dbcFile.download = `${fileName}.dbc`;
    dbcFile.click();
    URL.revokeObjectURL(url);
  };

  const downloadDBCFile = async (dbcID: string, dbcName: string) => {
    const res = await fetchDBCFile(dbcID);
    const dbcFileContent = res.results.dbc;
    const dbcFileName = dbcName;
    const contentType = "text/plain";

    downloadFile(dbcFileContent, dbcFileName, contentType);
  };

  const fetchDBCJSON = async (dbcID: string) => {
    setNewDBCOutputStream("");
    setViewDBCTableModal(true);
    const res = await fetchParsedDBC(dbcID);
    setParsedDBCData(res);
  };

  const editDBCParser = (dbcID: string) => {
    const currentDBC = DBCData.find((dbc) => {
      return dbc.id === dbcID;
    });
    setSelectedDBC(currentDBC);
    setOperationType(DBCOperationType.Edit);
    setNewDBCOutputStream("");
    setOpenCreateOrEditDBCModal(true);
  };

  function sortShards(shards: DBCOffsetResponse) {
    // Get the keys and sort them
    const sortedKeys = Object.keys(shards).sort((a, b) => {
      const shardNumberA = parseInt(a.split("-")[1], 10);
      const shardNumberB = parseInt(b.split("-")[1], 10);
      return shardNumberA - shardNumberB; // Sort numerically
    });

    // Rebuild the object in sorted order
    const sortedShards = {};
    sortedKeys.forEach((key) => {
      sortedShards[key] = shards[key];
    });

    return sortedShards;
  }

  useEffect(() => {
    handleUpdate(false);

    // Clean up the timeout and abort the fetch when the component unmounts
    return () => {
      isMounted.current = false;
      if (timeoutId.current) clearTimeout(timeoutId.current);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [handleUpdate]);

  useEffect(() => {
    document.title = "DBC Parsers | Bytebeam";

    setTimeout(() => {
      setNewDBCOutputStream("");
    }, 5000); // remove popup after a while
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="calc(100vh - 130px)"
        fontSize="1.5rem"
        loadingText={
          location.pathname.includes("dbc") ? "Loading DBCs" : "Loading..."
        }
      />
    );
  }

  return (
    <Grid>
      <CreateOrEditDBCModal
        open={openCreateOrEditDBCModal}
        onOpen={() => setOpenCreateOrEditDBCModal(true)}
        onClose={handleModalClose}
        selectedDBC={selectedDBC}
        DBCData={DBCData}
        operationType={operationType}
        setNewDBCOutputStream={(stream) => setNewDBCOutputStream(stream)}
        handleUpdate={() => handleUpdate(true)}
      />
      <ViewDBCTableModal
        open={viewDBCTableModal}
        onOpen={() => setViewDBCTableModal(true)}
        onClose={() => {
          setViewDBCTableModal(false);
          setParsedDBCData(undefined);
        }}
        parsedDBC={parsedDBCData}
      />
      <UpdateDBCParsingModal
        isOpen={isUpdateDBCParsingModalOpen}
        onClose={() => {
          setSelectedDBC(undefined);
          setIsUpdateDBCParsingModalOpen(false);
        }}
        selectedDBC={selectedDBC}
      />
      <Grid.Row>
        <Grid.Column>
          <Button
            id="addDBCButton"
            primary
            floated="right"
            icon
            labelPosition="left"
            onClick={() => {
              setNewDBCOutputStream("");
              setSelectedDBC(undefined);
              setOperationType(DBCOperationType.Create);
              setOpenCreateOrEditDBCModal(true);
            }}
          >
            <Icon name="plus" />
            Add DBC Parser
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table id="DBCTable" celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign="center">
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Version
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  Type
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Input Table
                  <PopupWithLowZIndex
                    content={"This Streams Can No Longer Be Updated"}
                    trigger={
                      <Icon
                        style={{
                          marginLeft: "8px",
                          justifyContent: "center",
                        }}
                        name="info circle"
                      />
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Output Table
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Cycle Time
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign="center">
                  Last Processed Timestamp
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {DBCData.length !== 0 ? (
                // Sort the DBCs by name, without mutating the original array as it's a state variable
                [...DBCData]
                  .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                  })
                  .map((dbc) => {
                    const sortedOffsets = sortShards(dbc.offsets);
                    return (
                      <Table.Row key={dbc.id}>
                        <Table.Cell textAlign="center">
                          <TextWithToolTip text={dbc.id} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <TextWithToolTip text={dbc.name} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <TextWithToolTip text={dbc.version} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {dbc.dbc_type}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <TextWithToolTip text={dbc.input_table} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <TextWithToolTip text={dbc.output_table} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {dbc.period} ms
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {capitalizeFirstLetter(dbc.status || "--")}
                        </Table.Cell>
                        {Object.keys(sortedOffsets).length !== 0 ? (
                          <Table.Cell textAlign="center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              {Object.keys(sortedOffsets).map((key) => {
                                return (
                                  <>
                                    {sortedOffsets[key] ? (
                                      <PopupWithLowZIndex
                                        content={moment(
                                          sortedOffsets[key]
                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                        trigger={
                                          <OffsetDiv key={key}>
                                            {key}:
                                            <span
                                              style={{ paddingRight: "5px" }}
                                            />
                                            {moment
                                              .duration(
                                                sortedOffsets[key] -
                                                  new Date().valueOf()
                                              )
                                              .humanize()}{" "}
                                            ago
                                          </OffsetDiv>
                                        }
                                      />
                                    ) : (
                                      "--"
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </Table.Cell>
                        ) : (
                          <Table.Cell textAlign="center">--</Table.Cell>
                        )}
                        <Table.Cell textAlign="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "12px",
                              flexWrap: "nowrap",
                            }}
                          >
                            {renderToggleButton(dbc, dbc.status ?? "stopped")}
                            {renderDeleteButton(dbc)}
                            <Dropdown
                              title="More Options"
                              style={{ border: "none" }}
                              direction="left"
                              trigger={<Icon name="ellipsis vertical" />}
                              icon={null}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  title="Update DBC Parsing"
                                  disabled={
                                    dbc.status === "started" ||
                                    dbc.dbc_type === "obd" ||
                                    dbc.dbc_type === "j1939"
                                  }
                                  onClick={() => {
                                    setNewDBCOutputStream("");
                                    setIsUpdateDBCParsingModalOpen(true);
                                    setSelectedDBC(dbc);
                                  }}
                                >
                                  <ButtonGroupedIcon
                                    icon={{
                                      name: "file alternate outline",
                                    }}
                                    cornerIcon={{
                                      name: "pencil",
                                      corner: "bottom right",
                                    }}
                                    style={{
                                      marginRight: "12px",
                                    }}
                                  />
                                  Update DBC Parsing
                                </Dropdown.Item>
                                <Dropdown.Item
                                  title="View/Edit DBC Parser"
                                  onClick={() => {
                                    setNewDBCOutputStream("");
                                    editDBCParser(dbc.id);
                                  }}
                                >
                                  <Icon name="edit" />
                                  View/Edit DBC Parser
                                </Dropdown.Item>
                                <Dropdown.Item
                                  title="View Parsed DBC"
                                  onClick={() => {
                                    setNewDBCOutputStream("");
                                    fetchDBCJSON(dbc.id);
                                  }}
                                  disabled={dbc.dbc_type === "j1939"}
                                >
                                  <Icon name="eye" />
                                  View Parsed DBC
                                </Dropdown.Item>
                                {dbc.dbc_type === "custom" && (
                                  <Dropdown.Item
                                    title="Download DBC file"
                                    onClick={() => {
                                      setNewDBCOutputStream("");
                                      downloadDBCFile(dbc.id, dbc.name);
                                    }}
                                  >
                                    <BrowserUpdatedIcon
                                      height="14px"
                                      id={`downloadDBCIcon${dbc.id}`}
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "12px",
                                        opacity: "0.7",
                                      }}
                                    />
                                    Download DBC file
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
              ) : (
                <Table.Row>
                  <Table.Cell textAlign="center" colSpan={11}>
                    <ErrorMessage message={"No DBCs found!"} />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
