import {
  DevicePulseMetaData,
  DevicePulseThreshold,
  defaultThresholds,
} from "./PanelDef";

import {
  PartialMetaData,
  PanelEditComponent,
  EditComponentProps,
} from "../PanelDef";
import React from "react";
import {
  EditMetaRoot,
  EditMetaRow,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  EditPanelFormContainer,
} from "../util";
import { Icon, Popup, Tab } from "semantic-ui-react";

type EditDevicePulseMetaProps = EditComponentProps<DevicePulseMetaData>;

export type EditDevicePulseMetaState = {
  table: string;
  thresholds: DevicePulseThreshold[];
  deviceDashboardId: string;
  error: boolean;
};

export class EditDevicePulseMeta extends PanelEditComponent<
  DevicePulseMetaData,
  EditDevicePulseMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();

  constructor(props: EditDevicePulseMetaProps) {
    super(props);

    if (props.panelMeta && props.panelMeta.thresholds.length > 0) {
      this.state = {
        table: props.panelMeta?.table || "",
        thresholds: props.panelMeta.thresholds,
        deviceDashboardId: props.panelMeta.device_dashboard_id,
        error: false,
      };
    } else {
      this.state = {
        table: props.panelMeta?.table || "",
        thresholds: JSON.parse(JSON.stringify(defaultThresholds)),
        deviceDashboardId: "",
        error: false,
      };
    }
  }

  getPanelMeta(): PartialMetaData<DevicePulseMetaData> {
    const meta: DevicePulseMetaData = {
      id: this.props.panelMeta.id,
      table: this.state.table || "",
      type: "device_pulse",
      title: this.titleRef.current?.value || "",
      device_dashboard_id: this.state.deviceDashboardId,
      description: this.descriptionRef.current?.value || "",
      thresholds: this.state.thresholds,
      includeAll: true,
    };

    let errorMessage = "";
    if (!meta.thresholds.every((t) => t.threshold > 0)) {
      errorMessage = "Thresholds must be greater than 0";
    }

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta),
      errorMessage: errorMessage !== "" ? errorMessage : undefined,
    };
  }

  isValidPanelMeta(meta: DevicePulseMetaData) {
    const isValidThresholds =
      meta.thresholds.length > 0 &&
      meta.thresholds.every((t) => t.threshold > 0);

    this.setState({ error: !isValidThresholds });
    return isValidThresholds;
  }

  setThreshold(i: number, value: string) {
    let thresholds = this.state.thresholds;

    thresholds[i].threshold = parseInt(value);

    this.setState({ thresholds: thresholds });
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  setDashboardIdColumn(_event, data) {
    this.setState({
      deviceDashboardId: data.value,
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let deviceDashboardIdOptions:
      | Array<{
          key: string;
          value: string;
          text: string;
        }>
      | undefined = undefined;
    let filteredDeviceDashboards: any = this.props.dashboards?.filter(
      (item) => {
        if (item.type === "fleet") return false;
        else return true;
      }
    );
    deviceDashboardIdOptions = filteredDeviceDashboards.map((item) => {
      const obj = {
        key: String(item.id),
        value: String(item.id),
        text: `${item.id} - ${item.title}`,
      };
      return obj;
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <div style={{ width: "50%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.props.panelMeta.table}
                    elementid={"DevicePulseTable"}
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>Thresholds in seconds:</EditMetaRow>
              {this.state.thresholds.map((threshold, i) => {
                return (
                  <EditMetaRow key={i}>
                    <div style={{ width: "48%", marginTop: "16px" }}>
                      <EditAnimatedMetaInput
                        value={threshold.threshold}
                        label={`Threshold ${i + 1}`}
                        type="number"
                        onChangeEvent={(e) =>
                          this.setThreshold(i, e.target.value)
                        }
                        error={
                          this.state.error &&
                          (isNaN(threshold.threshold) ||
                            threshold.threshold <= 0)
                        }
                      />
                    </div>
                  </EditMetaRow>
                );
              })}
              <ThinDivider />
              <EditMetaRow>
                Select Device dashboard{" "}
                <Popup
                  inverted
                  content="Click on the pulse to open this device dashboard with the selected device"
                  trigger={
                    <Icon style={{ marginLeft: "5px" }} name="info circle" />
                  }
                />
              </EditMetaRow>
              <EditMetaRow>
                <div style={{ width: "48%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Device dashboard ID"
                    text={
                      deviceDashboardIdOptions?.filter(
                        (item) => item.value === this.state.deviceDashboardId
                      )[0]?.text
                    }
                    search
                    selection
                    options={deviceDashboardIdOptions}
                    onChange={this.setDashboardIdColumn.bind(this)}
                    value={this.state.deviceDashboardId}
                    elementid={"LocateDevicesDashboardID"}
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
