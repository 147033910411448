import React, { useEffect, useState } from "react";
import { Modal, Button, Icon, Form, TextArea } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { Form as JsonSchemaForm } from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";
import ThemeSchema from "../../../../../theme/schema";
import { useUser } from "../../../../../context/User.context";
import { ActionDropdownType } from "../../../../../BytebeamClient";
import { beamtoast } from "../../../../common/CustomToast";
import { capitalizeFirstLetter } from "../../../util";

type CustomActionModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly selectedAction: ActionDropdownType;
  readonly payload: Record<string, any> | string;
  readonly setPayload: (payload: Record<string, any> | string) => void;
};

function CustomActionModal(props: CustomActionModalProps) {
  const { isOpen, onClose, selectedAction, setPayload } = props;
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [customJSON, setCustomJSON] = useState(
    props.payload !== "" ? JSON.stringify(props.payload, null, 2) : "{}"
  );
  const [customText, setCustomText] = useState(props.payload as string);

  useEffect(() => {
    setCustomJSON("{}");
    setCustomText("");
  }, [selectedAction]); //eslint-disable-line react-hooks/exhaustive-deps

  // Reset state and close modal
  const closeModal = () => {
    onClose();
  };

  function handleSubmit() {
    const payloadType = selectedAction.payload_type;
    let payload: Record<string, any> | string = "";

    if (payloadType === "json") {
      try {
        payload = JSON.parse(customJSON);

        if (
          (Array.isArray(payload) && payload?.length === 0) ||
          Object.keys(payload).length === 0
        ) {
          beamtoast.error("Please enter JSON payload.");
          return;
        }
        setPayload(payload);
        closeModal();
      } catch (e) {
        beamtoast.error("Please enter correct JSON payload.");
        console.log("Error parsing JSON payload", e);
        return;
      }
    } else if (payloadType === "text") {
      if (customText?.trim() === "") {
        beamtoast.error("Please enter text payload.");
        return;
      } else {
        payload = customText?.trim();

        setPayload(payload);
        closeModal();
      }
    }
  }

  function renderForm() {
    const payloadType = selectedAction.payload_type;

    if (payloadType === "json") {
      if (selectedAction.json_schema && selectedAction.json_ui_schema) {
        const jsonSchema = JSON.parse(selectedAction.json_schema);
        const jsonUISchema = JSON.parse(selectedAction.json_ui_schema);

        jsonUISchema["ui:submitButtonOptions"] = {
          norender: true,
        };
        return (
          <JsonSchemaForm
            schema={jsonSchema}
            uiSchema={jsonUISchema}
            formData={JSON.parse(customJSON)}
            onChange={({ formData }) => {
              setCustomJSON(JSON.stringify(formData));
            }}
            validator={validator}
          />
        );
      }

      return (
        <>
          <p>Enter JSON payload</p>
          <AceEditor
            height="300px"
            width="100%"
            placeholder={"Enter custom JSON payload"}
            mode="json"
            theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
            name="custom-json"
            fontSize={14}
            value={customJSON}
            onChange={(val) => {
              setCustomJSON(val);
            }}
            focus={true}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "4px",
              border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
              boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
            }}
          />
        </>
      );
    } else if (payloadType === "text") {
      return (
        <>
          <p>Enter Text payload</p>
          <Form style={{ marginBottom: "16px" }}>
            <TextArea
              autoFocus
              rows={5}
              placeholder="Text payload"
              value={customText}
              onChange={(e) => setCustomText(e.target.value)}
            />
          </Form>
        </>
      );
    }
  }

  return (
    <Modal open={isOpen} onClose={closeModal} size="tiny" className="dark">
      <Modal.Header
        id={`${selectedAction.payload_type}_${
          selectedAction.payload_type === "json" &&
          selectedAction.json_schema &&
          selectedAction.json_ui_schema
            ? "schema_defined"
            : "no_schema"
        }`}
      >
        {capitalizeFirstLetter(selectedAction.text)}: Upload{" "}
        <b>{selectedAction.payload_type}</b> payload
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>{renderForm()}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => closeModal()}>
          <Icon name="remove" /> No
        </Button>
        <Button primary onClick={() => handleSubmit()}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CustomActionModal;
