import React, { useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon,
} from "react-google-maps";

import moment from "moment";

import { GOOGLE_MAPS_API_KEY } from "../../../../../../constants/index";
import {
  MicelioLocateDevicesMetaData,
  MicelioLocateDevicesData,
} from "./PanelDef";
import { PanelViewComponent } from "../../PanelDef";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { DeviceCoordinates } from "../../TrackDevices/PanelDef";
import { ReplayState } from "../../../DashboardHeader";
import { darkTheme, lightTheme } from "../../TrackDevices/MapStyle";
import { Settings } from "../../../../../../util";

import { Column } from "../../../../util";
import {
  stateIcons,
  InfoWindowContainer,
  InfoWindowHeader,
  InfoWindowDivider,
  InfoWindowRow,
  AlertText,
  AlertIcon,
  alertIcons,
  blrPolygon,
} from "../../map-util";
import { getTenantFromURL } from "../../../../../../BytebeamClient";
import { useUser } from "../../../../../../context/User.context";

type MarkerWithInfoWindowProps = {
  deviceId: string;
  coordinates: DeviceCoordinates;
  deviceDashboardId: string;
  metadata: { [key: string]: string };
  config: Array<Array<[number, number]>>;
  deviceState: { [key: string]: any };
  settings: Settings;
  showGeoFence: boolean;

  isInfoWindowOpen: boolean;
  openInfoWindow: (deviceId: string) => void;
  closeInfoWindow: (deviceId: string) => void;
};

export class MarkerWithInfoWindow extends React.Component<MarkerWithInfoWindowProps> {
  state = {
    infoWindowOpen: false,
  };

  render() {
    const { deviceId, coordinates, deviceState, deviceDashboardId } =
      this.props;
    const { status, batterySoC, odometer, alertLevel, alerts } = deviceState;
    const showInfoWindow =
      this.props.isInfoWindowOpen && !!this.props.deviceDashboardId;
    const serialNumber = this.props.settings["serial_number"];
    const currentTenant = getTenantFromURL();

    let url = `/projects/${currentTenant}/dashboards/${deviceDashboardId}?id=${deviceId}`;

    if (
      this.props.metadata &&
      this.props.settings &&
      serialNumber &&
      this.props.metadata[serialNumber]
    ) {
      url = `/projects/${currentTenant}/dashboards/${deviceDashboardId}?${serialNumber}=${this.props.metadata[serialNumber]}`;
    }

    let position = new google.maps.LatLng(
      coordinates.latitude,
      coordinates.longitude
    );

    const icon = stateIcons?.[status]?.[alertLevel];

    let polygon = blrPolygon.map((x) => new google.maps.LatLng(x[1], x[0]));

    if (this.props?.config?.[0]) {
      polygon = this.props.config[0].map(
        (x) => new google.maps.LatLng(x[1], x[0])
      );
    }

    return (
      // @ts-ignore
      <Marker
        position={position}
        icon={{
          url: icon,
          scaledSize: new google.maps.Size(32, 32),
        }}
        key={deviceId}
        onClick={() => this.props.openInfoWindow(deviceId)}
      >
        {showInfoWindow && (
          <>
            {/* @ts-ignore */}
            <InfoWindow
              onCloseClick={() => this.props.closeInfoWindow(deviceId)}
            >
              <InfoWindowContainer>
                <InfoWindowHeader>
                  <div>
                    <a
                      style={{ fontWeight: "bold" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={url}
                    >
                      {this.props.metadata["registration_number"]}
                    </a>
                    |{status}
                  </div>
                  <div style={{ paddingRight: "50px" }}>{batterySoC}%</div>
                </InfoWindowHeader>

                <InfoWindowDivider />

                <Column>
                  <InfoWindowRow>
                    <div>Total Distance:</div>
                    <div>{odometer.toFixed(2)} km</div>
                  </InfoWindowRow>
                  {/* <InfoWindowRow>
                    <div>Total Idle Time:</div>
                    <div>10h</div>
                  </InfoWindowRow> */}
                  <InfoWindowRow>
                    <AlertText>{alerts.join(" | ")}</AlertText>
                  </InfoWindowRow>
                  <InfoWindowRow>
                    {alerts.map((alert, i) => {
                      return (
                        <AlertIcon
                          key={`${alert}-${i}`}
                          src={(alertIcons[alert] || {}).light}
                          alt={alert}
                        />
                      );
                    })}
                  </InfoWindowRow>

                  <InfoWindowRow>
                    <div>
                      {moment(coordinates.timestamp).format(
                        "DD-MM-YY HH:mm:ss"
                      )}
                    </div>
                  </InfoWindowRow>
                </Column>
              </InfoWindowContainer>
            </InfoWindow>
            {this.props.showGeoFence ? (
              <Polygon
                paths={polygon}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                }}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </Marker>
    );
  }
}

type MapComponentProps = {
  devices: MicelioLocateDevicesData;
  deviceDashboardId: string;
  replayTimestamp: number;
  replayStep: number;
  replayState: ReplayState;
  settings: Settings;
  children?: React.ReactNode;
};

const MapComponent = compose<MapComponentProps, MapComponentProps>(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
      GOOGLE_MAPS_API_KEY +
      "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: "8px" }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: MapComponentProps) => {
  const mapsRef = React.createRef<GoogleMap>();

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";
  const mapTheme = theme === "light" ? lightTheme : darkTheme;

  const [infoWindowDeviceId, setInfoWindowDeviceId] = React.useState("");

  const openInfoWindow = (deviceId: string) => {
    setInfoWindowDeviceId(deviceId);
  };

  const closeInfoWindow = (deviceId: string) => {
    if (deviceId === infoWindowDeviceId) {
      setInfoWindowDeviceId("");
    }
  };

  useEffect(() => {
    if (props.devices.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      props.devices.forEach((device) => {
        let coords = device.coordinates[0];
        bounds.extend(
          new google.maps.LatLng(coords.latitude, coords.longitude)
        );
      });

      mapsRef.current?.fitBounds(bounds);
    }

    // Disabling eslint as it asks us to either add mapsRef or remove the array completely.
    // We can't do either as in both cases whenever an infowindow is rendered this component gets re-rendered and
    // the map zooms back to the bounds. We want the zoom level to stay as is when an info window is opened
    // eslint-disable-next-line
  }, [props.devices]);

  return (
    // @ts-ignore
    <GoogleMap
      ref={mapsRef}
      defaultZoom={17}
      defaultCenter={{ lat: 12.927381, lng: 77.637729 }}
      defaultOptions={{
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
        },
        panControl: true,
        zoomControl: false,
        rotateControl: true,
        fullscreenControl: false,
        styles: mapTheme,
      }}
    >
      {/* @ts-ignore */}
      <MarkerClusterer
        minimumClusterSize={3}
        imagePath="../PanelIcons/mapCluster/m"
      >
        {props.devices.map((device) => {
          const coordinates = device.coordinates;

          let coordIndex = coordinates.length - 1;

          if (
            props.replayState === ReplayState.ReplayRunning ||
            props.replayState === ReplayState.ReplayPaused
          ) {
            coordIndex = props.replayStep;

            if (coordIndex > coordinates.length - 1) {
              coordIndex = coordinates.length - 1;
            }
          }

          const currentLocation = coordinates[coordIndex];

          return (
            <MarkerWithInfoWindow
              key={device.id}
              deviceId={device.id}
              coordinates={currentLocation}
              metadata={device.metadata}
              deviceState={device.state}
              // @ts-ignore
              config={device.device_config}
              settings={props.settings}
              deviceDashboardId={props.deviceDashboardId}
              showGeoFence={true}
              isInfoWindowOpen={device.id === infoWindowDeviceId}
              openInfoWindow={openInfoWindow}
              closeInfoWindow={closeInfoWindow}
            />
          );
        })}
      </MarkerClusterer>
    </GoogleMap>
  );
});

export class MicelioViewLocateDevices extends PanelViewComponent<
  MicelioLocateDevicesMetaData,
  MicelioLocateDevicesData
> {
  render() {
    return (
      <MapComponent
        devices={this.props.data}
        deviceDashboardId={this.props.panelMeta.device_dashboard_id || ""}
        replayTimestamp={this.props.replayStep}
        replayState={this.props.replayState}
        replayStep={this.props.replayStep}
        settings={this.props.settings}
      />
    );
  }
}
