import React from "react";
import { AggregateValueMetaData } from "./PanelDef";
import { Tab } from "semantic-ui-react";
import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  LabelHeading,
  DisplayValueDiv,
  ToggleLabel,
  conditionalAggregators,
  EditPanelFormContainer,
  StyledInputDiv,
  DateTimeStreamsFieldsTypes,
} from "../util";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { TableInfo } from "../../../../../BytebeamClient";
import { DashboardType } from "../../EditDashboardModal";
import { PrecisionInlineInput } from "../../../../common/commonStyledComps";
import Toggle from "../../../../common/Toggle";
export type EditAggregateValueMetaProps = {
  panelMeta: AggregateValueMetaData;
  tables: TableInfo;
};

export type EditAggregateValueMetaState = {
  table: string;
  column: string;
  columnType: string;
  aggregator: string[];
  second_aggregator?: string;
  textSize: number;
  autoTextSize: boolean;
  error: boolean;
  fleetTableView: boolean;
  enableUnits: boolean;
  autoScaleUnits: boolean;
  enableRoundoff: boolean;
  roundOffPrecision: number;
  secondAggregatorGroupBy?: boolean;
};

export class EditAggregateValueMeta extends PanelEditComponent<
  AggregateValueMetaData,
  EditAggregateValueMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  prefixRef = React.createRef<HTMLInputElement>();
  suffixRef = React.createRef<HTMLInputElement>();

  constructor(props: EditAggregateValueMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        autoTextSize: props.panelMeta.autoTextSize,
        textSize: props.panelMeta.textSize,
        table: props.panelMeta.table,
        column: props.panelMeta.column,
        error: false,
        fleetTableView: props.panelMeta.fleetTableView,
        enableUnits: props.panelMeta.enableUnits ?? false,
        autoScaleUnits: props.panelMeta.autoScaleUnits ?? false,
        enableRoundoff: props.panelMeta.enableRoundoff ?? true,
        roundOffPrecision: props.panelMeta.roundOffPrecision ?? 3,
        columnType:
          props.tables[props.panelMeta.table]?.filter(
            (item) => item?.name === props.panelMeta?.column
          )[0]?.type ?? "",
        aggregator:
          typeof props.panelMeta.aggregator === "string"
            ? [props.panelMeta.aggregator]
            : props.panelMeta.aggregator,
        ...(props.panelMeta.second_aggregator
          ? { second_aggregator: props.panelMeta.second_aggregator }
          : {}),
        ...(props.panelMeta.aggregate_over_first_result !== undefined && {
          secondAggregatorGroupBy: props.panelMeta.aggregate_over_first_result,
        }),
      };
    } else {
      this.state = {
        textSize: 50,
        autoTextSize: true,
        table: "",
        column: "",
        columnType: "",
        aggregator: [],
        error: false,
        fleetTableView: false,
        enableUnits: false,
        autoScaleUnits: false,
        enableRoundoff: true,
        roundOffPrecision: 3,
        secondAggregatorGroupBy: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<AggregateValueMetaData> {
    const meta: AggregateValueMetaData = {
      type: "aggregate_value",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      column: this.state.column || "",
      prefix: this.prefixRef.current?.value || "",
      suffix: this.suffixRef.current?.value || "",
      aggregator:
        this.state.aggregator?.length > 0 ? this.state.aggregator : [],
      textSize: this.state.textSize || 50,
      autoTextSize: this.state.autoTextSize,
      fleetTableView: this.state.fleetTableView,
      enableUnits: this.state.enableUnits,
      autoScaleUnits: this.state.autoScaleUnits,
      enableRoundoff: this.state.enableRoundoff,
      roundOffPrecision: this.state.roundOffPrecision || 3,
      ...(this.state.second_aggregator
        ? {
            second_aggregator: this.state.second_aggregator,
            aggregate_over_first_result: this.state.secondAggregatorGroupBy,
          }
        : {}),
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: AggregateValueMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (
      !(!!meta.table && !!meta.column && meta.aggregator.length > 0) &&
      type === "submit"
    ) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && !!meta.column && meta.aggregator.length > 0;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      column: "",
      columnType: "",
      aggregator: [],
      second_aggregator: "",
      secondAggregatorGroupBy: false,
    });
  }

  setColumn(_event, data) {
    const columnType = data.options.filter(
      (item) => item.value === data.value
    )[0].type;
    this.setState({
      column: data.value,
      columnType: columnType,
      aggregator: [],
      second_aggregator: "",
      secondAggregatorGroupBy: false,
    });
  }

  setAggregate(_event, data) {
    this.setState({
      aggregator: data.value,
      second_aggregator: "",
      secondAggregatorGroupBy: false,
    });
  }

  setSecondAggregate(_event, data) {
    this.setState({
      second_aggregator: data.value,
      secondAggregatorGroupBy: false,
    });
  }

  handleSliderChange = (event) => {
    this.setState({ textSize: parseFloat(event.target.value) });
  };

  autoTextSizeToggle() {
    const toggled = !this.state.autoTextSize;
    this.setState({ autoTextSize: toggled });
  }

  fleetTableViewToggle() {
    const toggled = !this.state.fleetTableView;
    this.setState({ fleetTableView: toggled });
  }

  enableUnitsToggle() {
    const toggled = !this.state.enableUnits;
    this.setState({ enableUnits: toggled });
  }

  enableAutoScaleUnitsToggle() {
    const toggled = !this.state.autoScaleUnits;
    this.setState({ autoScaleUnits: toggled });
  }

  enableRoundoffToggle() {
    const toggled = !this.state.enableRoundoff;
    this.setState({ enableRoundoff: toggled });
  }

  handleRoundOffPrecisionChange = (e) => {
    const value = e.target.value;
    if (!value || Number.isNaN(value)) {
      return;
    }
    const newValue = parseInt(value, 10);
    if (newValue > 0) {
      this.setState({
        roundOffPrecision: newValue,
      });
    } else {
      // Reset to the default value or previous valid value
      e.target.value = this.state.roundOffPrecision;
    }
  };

  handleSecondAggregatorGroupByToggle = () => {
    this.setState((prevState) => ({
      secondAggregatorGroupBy: !prevState.secondAggregatorGroupBy,
    }));
  };

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];

    if (this.props?.tables?.[this.state?.table]) {
      columnTypeOptions = this.props.tables[this.state.table].map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
            type: f.type,
          };
        }
      );
    } else {
      // Handle the case when the data is not available
      columnTypeOptions = [];
    }

    let aggregateOptions = [
      ...conditionalAggregators(this.state.columnType)?.filter((agg) =>
        DateTimeStreamsFieldsTypes.includes(this.state.columnType)
          ? agg !== "sum" && agg !== "avg"
          : agg
      ),
      "first",
      "last",
    ].map((a) => {
      return {
        key: a,
        text: a,
        value: a,
      };
    });
    aggregateOptions.push({
      key: "count_distinct",
      text: "count distinct",
      value: "count_distinct",
    });

    aggregateOptions = aggregateOptions.filter(
      (option) => option.value !== "minmax"
    );

    if (
      !["String", "Nullable(String)", "Bool", "Nullable(Bool)"].includes(
        this.state.columnType
      )
    ) {
      aggregateOptions.push({
        key: "delta",
        text: "delta",
        value: "delta",
      });
    }

    let secondAggregateOptions = conditionalAggregators("default")
      .filter((agg) => agg !== "minmax")
      .map((a) => {
        return {
          key: a,
          text: a,
          value: a,
        };
      });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table || ""}
                    value={this.state.table || ""}
                    elementid={"AggregateValueTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={this.state.column || "Select Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={columnTypeOptions}
                    onChange={this.setColumn.bind(this)}
                    defaultValue={this.state.column || ""}
                    value={this.state.column || ""}
                    elementid={"AggregateValueColumn"}
                    error={this.state.error && !this.state.column}
                  />
                </StyledInputDiv>

                {this.props.dashboardType === DashboardType.FleetDashboard && (
                  <StyledInputDiv
                    width="48%"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                      opacity: this.state.second_aggregator ? 1 : 0.5,
                    }}
                  >
                    <Toggle
                      id="second-aggregator-group-by"
                      size="medium"
                      checked={this.state.secondAggregatorGroupBy}
                      onChange={() =>
                        this.handleSecondAggregatorGroupByToggle()
                      }
                      disabled={!this.state.second_aggregator}
                    />
                    Enable Group By on Second Aggregator
                  </StyledInputDiv>
                )}
              </EditMetaRow>
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Aggregator"
                    search
                    selection
                    multiple
                    disabled={this.state.column === ""}
                    options={aggregateOptions}
                    onChange={this.setAggregate.bind(this)}
                    defaultValue={
                      this.state.aggregator?.length > 0
                        ? this.state.aggregator
                        : []
                    }
                    value={
                      this.state.aggregator?.length > 0
                        ? this.state.aggregator
                        : []
                    }
                    elementid={"AggregateValueAggregator"}
                    error={
                      this.state.error && this.state.aggregator.length === 0
                    }
                  />
                </StyledInputDiv>
                {this.props.dashboardType === DashboardType.FleetDashboard && (
                  <StyledInputDiv width="48%" marginTop="10px">
                    <EditAnimatedMetaDropdown
                      clearable
                      placeholder="Second Aggregator(Optional)"
                      search
                      selection
                      disabled={
                        this.state.column === "" ||
                        this.state.aggregator.length !== 1
                      }
                      options={secondAggregateOptions}
                      onChange={this.setSecondAggregate.bind(this)}
                      defaultValue={this.state.second_aggregator}
                      value={this.state.second_aggregator}
                      elementid={"AggregateValueSecondAggregator"}
                      error={
                        this.state.error &&
                        this.state.second_aggregator?.length === 0
                      }
                    />
                  </StyledInputDiv>
                )}
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.prefixRef}
                    defaultValue={this.props.panelMeta.prefix}
                    label="Prefix"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.suffixRef}
                    defaultValue={this.props.panelMeta.suffix}
                    label="Suffix"
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              {this.props.dashboardType === DashboardType.FleetDashboard ? (
                <>
                  <EditMetaRow>
                    <ToggleLabel style={{ fontWeight: "bold" }}>
                      Enable Table View (Fleet Dashboard)
                    </ToggleLabel>
                    <ToggleSwitch
                      id="fleetTableView"
                      defaultChecked={this.props.panelMeta.fleetTableView}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={() => {
                        this.fleetTableViewToggle();
                      }}
                    />
                  </EditMetaRow>
                  <ThinDivider />
                </>
              ) : (
                <></>
              )}
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Enable Units
                </ToggleLabel>
                <ToggleSwitch
                  id="enableUnits"
                  defaultChecked={this.props.panelMeta.enableUnits}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.enableUnitsToggle();
                  }}
                />
              </EditMetaRow>
              {this.state.enableUnits && (
                <EditMetaRow>
                  <ToggleLabel style={{ fontWeight: "bold" }}>
                    Auto Scale Units
                  </ToggleLabel>
                  <ToggleSwitch
                    id="autoScaleUnits"
                    defaultChecked={this.props.panelMeta.autoScaleUnits}
                    disabled={false}
                    Text={["Yes", "No"]}
                    onToggleChange={() => {
                      this.enableAutoScaleUnitsToggle();
                    }}
                  />
                </EditMetaRow>
              )}
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Roundoff values to{" "}
                  <PrecisionInlineInput
                    autoFocus
                    id="roundOffPrecision"
                    defaultValue={this.state.roundOffPrecision}
                    placeholder="3"
                    type="number"
                    input={{
                      min: 1,
                      max: 9,
                      maxLength: 1,
                    }}
                    onChange={this.handleRoundOffPrecisionChange}
                  />{" "}
                  decimal places
                </ToggleLabel>
                <ToggleSwitch
                  id="enableRoundoff"
                  defaultChecked={this.state.enableRoundoff}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.enableRoundoffToggle();
                  }}
                />
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <p>
                  * These settings are applicable only for single aggregate
                  value and not for mobile layout
                </p>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Auto resize the Text
                </ToggleLabel>
                <ToggleSwitch
                  id="showRangeZeroToggle"
                  defaultChecked={this.props.panelMeta.autoTextSize}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.autoTextSizeToggle();
                  }}
                />
              </EditMetaRow>
              <EditMetaRow
                style={{
                  display: this.state.autoTextSize ? "none" : "flex",
                  marginLeft: "10px",
                }}
              >
                <LabelHeading style={{ fontWeight: "normal" }}>
                  Text Size
                </LabelHeading>
                <DisplayValueDiv>{this.state.textSize}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="15"
                    max="200"
                    value={this.state.textSize}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}
