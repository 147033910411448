import React, { ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import styled from "styled-components";

type ErrorMessageProps = {
  containerHeight?: string;
  message?: ReactNode | string;
  messageLess?: boolean;
  errorMessage?: boolean;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
  textMarginTop?: string;
  textColor?: string;
  iconColor?: string;
  iconSize?: IconSizeProp;
};

const ErrorMessageContainer = styled.div<ErrorMessageProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${(props) =>
    props.containerHeight ? props.containerHeight : "fit-content"} !important;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "10px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "10px"};
  font-size: 26px;
  color: ${({ theme, iconColor }) => iconColor ?? theme.colors["text"]};
  h3 {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
    color: ${({ theme, textColor }) => textColor ?? theme.colors["text"]};
    margin-top: ${({ textMarginTop }) => textMarginTop ?? "10px"};
  }
`;

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { message, messageLess, errorMessage, iconSize } = props;

  return (
    <ErrorMessageContainer {...props}>
      {errorMessage ? (
        <Icon name="warning circle" size={iconSize ?? "big"} />
      ) : (
        <Icon name="info circle" size={iconSize ?? "big"} />
      )}
      {message ? (
        <h3>{message}</h3>
      ) : messageLess ? (
        <></>
      ) : (
        <h3>
          Something went wrong! Please contact{" "}
          <a href="mailto:support@bytebeam.io"> support@bytebeam.io </a> from
          your registered email id
        </h3>
      )}
    </ErrorMessageContainer>
  );
};
